import apiService from "./api";

const baseurl = process.env.REACT_APP_BASE_URL;

const sendHelpService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = `/support`;
  const method = "post";
  return apiService(url, method, body, true, headers, {}, baseurl);
};

export { sendHelpService };
