import React, { useEffect, useState } from "react";
import CustomIcon from "components/custom-icon";
import logoBlue from "assets/images/vegeel-blue.svg";
import { Dropdown, Menu, MenuProps } from "antd";
import { ReactComponent as IconBell } from "assets/icons/Iconbell.svg";
import { ReactComponent as IconExit } from "assets/icons/Iconexist.svg";
import { ReactComponent as IconDrowdown } from "assets/icons/IconDropdown.svg";
import { ReactComponent as IconUser } from "assets/icons/IconUserProfile.svg";
import { ReactComponent as IconLogout } from "assets/icons/IconLogout.svg";
import { ReactComponent as IconLock } from "assets/icons/IconLock.svg";
import dummy from "assets/images/dummy.png";
import iconUser from "assets/images/avatar-profile.svg";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "context";
import useModal from "helpers/useModal-hook";
import HelpModal from "components/header/help-modal";
import ProfileModal from "components/header/profle-modal";
import PasswordModal from "components/header/password-modal";
import LogoutModal from "components/header/logout-modal";
import { getTokenFromLocalStore } from "utils";

export default function Editor() {
  const [isLoading, setIsLoading] = useState(true);

  const { getUserInfo } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getTokenFromLocalStore("access_token") || null;

    if (!token) {
      navigate("/auth");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return;
    }
  }, [navigate]);

  const { open, loading, showModal, handleOk, handleCancel } = useModal();
  const {
    open: open1,
    loading: loading1,
    showModal: showModal1,
    handleOk: handleOk1,
    handleCancel: handleCancel1,
  } = useModal();
  const {
    open: open2,
    loading: loading2,
    showModal: showModal2,
    handleOk: handleOk2,
    handleCancel: handleCancel2,
  } = useModal();
  const {
    open: open3,
    loading: loading3,
    showModal: showModal3,
    handleOk: handleOk3,
    handleCancel: handleCancel3,
  } = useModal();
  const items: MenuProps["items"] = [
    {
      label: (
        <div className="py-1 pr-16" onClick={showModal1}>
          <span className="font-medium pl-1 leading-[24px] text-sm text-[#455468]">
            View Profile
          </span>
        </div>
      ),
      icon: <CustomIcon SvgIcon={IconUser} size={16} />,
      key: "0",
    },
    {
      label: (
        <div className="py-1 pl-1 pr-16" onClick={showModal2}>
          <span className="font-medium leading-[24px]  text-sm text-[#455468]">
            Change Password
          </span>
        </div>
      ),
      icon: <CustomIcon SvgIcon={IconLock} size={16} />,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          className="flex items-center justify-between w-full py-1 px-2"
          onClick={showModal3}
        >
          <span className="font-medium leading-[24px]  text-sm text-[#CB1A14]">
            Logout
          </span>
          <p className="text-[#667085] text-xs">v1.0</p>
        </div>
      ),
      icon: <CustomIcon SvgIcon={IconLogout} size={16} />,
      key: "2",
    },
  ];

  const userInfo = getUserInfo();

  return (
    <div className="flex flex-col ">
      <div className="h-[73.31px] bg-[#FAFAFA] px-[80px] flex items-center justify-between sticky top-0 border-b border-[#D7DFE9] z-10">
        <Link to="/">
          <img src={logoBlue} alt="vegeel blue" className="w-[114px]" />
        </Link>

        <div className="h-[36px] flex justify-between items-center gap-4">
          <div className="h-full w-[36px] bg-[#E5EAFC] flex justify-center items-center rounded-md">
            <CustomIcon SvgIcon={IconBell} size={18} />
          </div>
          <div className="h-[24px] w-[56px] flex justify-center items-center gap-[8px]">
            <span className="text-sm text-[#111111] font-medium leading-[24px] ">
              Help
            </span>
            <CustomIcon SvgIcon={IconExit} size={12} />
          </div>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <div className="py-[4px] px-[10px] bg-[#F2F5FF] border border-[#E5EAFC] rounded-[100px] flex items-center gap-1 cursor-pointer">
              <img
                src={userInfo?.profilePicture ?? dummy}
                alt={userInfo?.username}
                className="w-[28px] h-[28px] object-cover rounded-[100px]"
              />
              <CustomIcon SvgIcon={IconDrowdown} size={10} />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="px-[80px] py-7 h-full overflow-y-scroll bg-[#FAFBFC]">
        <Outlet />
      </div>
      <HelpModal
        open={open}
        loading={loading}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />

      <ProfileModal
        open={open1}
        loading={loading1}
        // handleOk={handleOk1}
        handleCancel={handleCancel1}
      />

      <PasswordModal
        open={open2}
        loading={loading2}
        // handleOk={handleOk2}
        handleCancel={handleCancel2}
      />

      <LogoutModal
        open={open3}
        loading={loading3}
        handleOk={handleOk3}
        handleCancel={handleCancel3}
      />
    </div>
  );
}
