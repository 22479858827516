import apiService from "./api";

const baseurl = process.env.REACT_APP_BASE_URL;

const onboardingService = async (body: any) => {
  const url = "/onboarding";
  const method = "post";

  return apiService(url, method, body, true);
};

export { onboardingService };
