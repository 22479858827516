"use client";
import { InputHTMLAttributes, useState } from "react";
// import { AppAssests } from "@/constants/assests";
// import Image from "next/image";
import React from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
// import * as ReactIcons from "react-icons";
// import { TbPencilExclamation } from "react-icons/tb";
import lockIcon from "../../../assets/icons/Iconlockopen.svg";
import emailIcon from "../../../assets/icons/Iconmail.svg";
import penIcon from "../../../assets/icons/write.png";
import helpIcon from "../../../assets/icons/help-circle-contained-gray.svg";
import computer from "../../../assets/icons/computer.svg";
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  type?: "text" | "password" | "email" | "number" | "implementer";
  textSize?: "xs" | "sm" | "lg" | "base";
  error?: string | undefined;
  touched?: boolean | undefined;
}

export const InputRest: React.FC<InputProps> = ({
  label,
  touched,
  type = "text",
  textSize = "base",
  error,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const leftIcon =
    type === "password" || type === "number"
      ? lockIcon
      : type === "email"
      ? emailIcon
      : type === "text"
      ? penIcon
      : type === "implementer"
      ? computer
      : "";
  return (
    <div className="w-full my-1">
      <p
        className={`text-label font-medium mb-2 text-${textSize}  text-[14px] text-[#3D4A5C]`}
      >
        {label}
      </p>

      {/* input */}
      <div className="border rounded-lg border-slate-300 py-2 px-3 flex gap-2 w-full">
        {leftIcon && <img alt="icon" src={leftIcon} className="h-5 w-5  " />}
        <input
          {...rest}
          type={showPassword ? "text" : type}
          className={`border-l focus:outline-none px-3 placeholder:text-slate300 w-full text-${textSize}`}
        />
        {type === "password" ? (
          <div onClick={togglePasswordVisibility} className="cursor-pointer">
            {showPassword ? (
              <IoEyeOutline className="h-5 w-5" />
            ) : (
              <IoEyeOffOutline className="h-5 w-5" />
            )}
          </div>
        ) : (
          <div className="cursor-pointer">
            <img alt="icon" src={helpIcon} className="h-5 w-6  " />
          </div>
        )}
      </div>

      <p className={`p-1  text-red-500 text-${textSize} `}>
        {touched && error && error}
      </p>
    </div>
  );
};
