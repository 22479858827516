import React from "react";
import CustomIcon from "components/custom-icon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PathProps } from "types";
import { useSidebarContext } from "context/sidebar";
import { useFrameworkContext } from "context";

export default function Navlink({ icon, link, title }: any) {
  const { pathname } = useLocation();
  const { updateSidebarState, updatePaths } = useSidebarContext();

  const navigate = useNavigate();
  // const { subscribedFrameworksLoading } = useFrameworkContext();

  const handleActivatePath = (path: string) => {
    updateSidebarState("COMPLIANCE", "Frameworks", true);
    updatePaths(path);
  };

  return (
    <button
      onClick={() => {
        updateSidebarState("COMPLIANCE", "Frameworks", false);
        navigate(link);
      }}
      className={`w-full h-[32px] flex items-center justify-start  ${
        pathname === link ? "active-sidebar" : ""
      } px-2 rounded-md hover:bg-gradient-to-r-blue`}
      // disabled={subscribedFrameworksLoading ? true : false}
    >
      <CustomIcon SvgIcon={icon} size={18} />
      <span className="text-[#FFFFFF] text-[14px] ml-1 leading-[24px]">
        {title}
      </span>
    </button>
  );
}
