import React from "react";
import logoBlue from "assets/images/vegeel-blue.svg";
import CustomIcon from "components/custom-icon";
import { ReactComponent as IconExist } from "assets/icons/Iconexist.svg";

export default function OnboardingHeader() {
  return (
    <div className="h-[73.31px] bg-[#FAFAFA] px-[80px] flex items-center justify-between sticky top-0">
      <img src={logoBlue} alt="vegeel blue" className="w-[114px]" />
      <a
        href="mailto:admin@vegeel.com"
        target="_blank"
        className="flex items-center gap-[10px]"
        rel="noreferrer"
      >
        <span className="text-[#455468] text-sm font-medium  leading-[24px]">
          Contact us
        </span>
        <CustomIcon SvgIcon={IconExist} size={10.5} />
      </a>
    </div>
  );
}
