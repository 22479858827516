import apiService from "./api";

const tenantPosturl = process.env.REACT_APP_BASE_URL;

const getAllRisgisterQuestion = async (page: number, size: number) => {
  return apiService(
    `/risk-questions?page=${page}&size=${size}`,
    "get",
    null,
    true
  );
};

const addNewRiskQuestion = async (body: any) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  const method = "post";
  const url = "/risk-assessment/create-risk";
  return apiService(url, method, body, true, headers, {}, tenantPosturl);
};

const postRiskQuestionsResponse = async (body: any[]) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  return apiService(
    "/risk-assessment/create-bulk",
    "post",
    body,
    true,
    headers,
    {},
    tenantPosturl
  );
};
const reSubmitQuestions = async (body: any, publicId: any) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  return apiService(
    `/risk-assessment/${publicId}`,
    "put",
    body,
    true,
    headers,
    {},
    tenantPosturl
  );
};

export {
  addNewRiskQuestion,
  getAllRisgisterQuestion,
  postRiskQuestionsResponse,
  reSubmitQuestions,
};
