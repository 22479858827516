import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "sonner";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-quill/dist/quill.snow.css";
import {
  AppProvider,
  FrameworkProvider,
  HeaderProvider,
  OnboardingProvider,
  SidebarProvider,
  CompanyInfoProvider,
  UploadProvider,
  AudithubProvider,
} from "context";
import { MyObjectProvider } from "context/audit/header";
import { AuthProvider } from "context/auth";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppProvider>
      <AuthProvider>
        <UploadProvider>
          <CompanyInfoProvider>
            <FrameworkProvider>
              <AudithubProvider>
                <OnboardingProvider>
                  <SidebarProvider>
                    <MyObjectProvider>
                      <HeaderProvider>
                        <App />
                        <Toaster
                          position="top-right"
                          richColors
                          duration={2000}
                        />
                      </HeaderProvider>
                    </MyObjectProvider>
                  </SidebarProvider>
                </OnboardingProvider>
              </AudithubProvider>
            </FrameworkProvider>
          </CompanyInfoProvider>
        </UploadProvider>
      </AuthProvider>
    </AppProvider>
  </React.StrictMode>
);

reportWebVitals();
