import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the structure for frameworkReq
interface FrameworkReqType {
  sectionNo: string;
  title: string;
  [key: string]: any; // Optional: in case there are additional fields
}

const MyObjectContext = createContext<{
  frameworkReq: FrameworkReqType | null;
  setFrameworkReq: React.Dispatch<
    React.SetStateAction<FrameworkReqType | null>
  >;
} | null>(null);

export const MyObjectProvider = ({ children }: { children: ReactNode }) => {
  const [frameworkReq, setFrameworkReq] = useState<FrameworkReqType | null>(
    null
  );

  return (
    <MyObjectContext.Provider value={{ frameworkReq, setFrameworkReq }}>
      {children}
    </MyObjectContext.Provider>
  );
};

export const useMyObjectContext = () => {
  const context = useContext(MyObjectContext);
  if (!context) {
    throw new Error(
      "useMyObjectContext must be used within a MyObjectProvider"
    );
  }
  return context;
};
