"use client";
import React, { useState, useEffect } from "react";

interface CheckboxProps {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (isChecked: boolean) => void;
  error?: any;
}

const Checkbox = ({
  label,
  checked = false,
  onChange,
  disabled,
  error,
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    if (onChange) {
      onChange(newCheckedState);
    }
  };

  return (
    <label className="flex items-center cursor-pointer w-fit">
      <div className="relative">
        <input
          type="checkbox"
          className={`hidden `}
          checked={isChecked}
          disabled={disabled}
          onChange={handleCheckboxChange}
        />
        <div
          className={`checkbox__control w-5 h-5 border  rounded-md flex items-center justify-center mr-2 ${
            error ? "  border-red-500 text-red-500" : "border-gray-300"
          }`}
        >
          {isChecked && (
            <svg
              className="w-5 h-5 text-slate-500"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </div>
      </div>
      <span
        className={`text-xs 2xl:text-base ${
          error ? "text-red-500" : "text-slate-600"
        }`}
      >
        {label}
      </span>
    </label>
  );
};

export default Checkbox;
