import apiService from "./api";

const trainingurl = process.env.REACT_APP_TRAINING_BASE_URL as string;

const getAllTrainingService = async () => {
  const url = `/trainings`;
  const method = "get";
  return apiService(url, method, null, true, {}, {}, trainingurl);
};

const getStats = async () => {
  const url = `/training/stats`;
  const method = "get";
  return apiService(url, method, null, true, {}, {}, trainingurl);
};

const getAllTrainingServiceP = async (trainingType: string) => {
  const url = `/training-questions?trainingType=${trainingType}`;
  const method = "get";
  return apiService(url, method, null, true);
};

const getTenantTraining = async (
  draft: boolean,
  category: string,
  userId: string
) => {
  const url = `/training?category=${category}&draft=${draft}&size=${30}&userId=${userId}`;
  const method = "get";
  return apiService(url, method, null, true);
};

const getUserTrainingResult = async () => {
  const url = "/training/user-training-result";
  const method = "get";
  return apiService(url, method, null, true);
};

const getOrgTrainingResult = async () => {
  const url = `/training/org-users-training-result?size=${1000}`;
  const method = "get";
  return apiService(url, method, null, true);
};

const submitTenantTraining = async (body: any) => {
  const url = "/training/create-bulk";
  const method = "post";
  return apiService(url, method, body, true);
};
const updateTenantTraining = async (body: any) => {
  const url = "/training/update-bulk";
  const method = "put";
  return apiService(url, method, body, true);
};

const getAllAnswers = async (body: any) => {
  const url = "/training/post-for-answers";
  const method = "post";
  return apiService(url, method, body, true);
};

export {
  getAllTrainingService,
  getTenantTraining,
  submitTenantTraining,
  getAllTrainingServiceP,
  getAllAnswers,
  getUserTrainingResult,
  getOrgTrainingResult,
  updateTenantTraining,
  getStats,
};
