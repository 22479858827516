import { Outlet } from "react-router-dom";
import { OnboardingTabs } from "../Tabs";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingSidebar from "./OnboardingSidebar";

export default function OnboardingContainer() {
  return (
    <div className="px-[80px] py-[10px] flex flex-col gap-4 w-full h-[calc(100%-74px)] overflow-y-scroll">
      <OnboardingHeader />
      <div className="my-3 w-full h-full">
        <OnboardingTabs />
        <div className="flex justify-between w-full h-full">
          <div className="py-3 w-full h-full">
            <Outlet />
          </div>
          <OnboardingSidebar />
        </div>
      </div>
    </div>
  );
}
