import { Modal } from "antd";
import { ReactComponent as close } from "assets/icons/x-03.svg";
import { ReactComponent as email } from "assets/icons/email.svg";
import { ReactComponent as rot } from "assets/icons/rot.svg";
import img from "assets/images/uk.jpg";
import img2 from "assets/images/text2.png";
import dummy from "assets/images/dummy.png";
import CustomIcon from "components/custom-icon";
import { useAppContext } from "context/home/app";
import {
  getAllPersonnelService,
  updatePersonnel,
  updateProfilePics,
} from "services/personnel";
import { getUserInfoService, uploadFilesService } from "services/app";
import React, { useEffect, useRef, useState } from "react";
import { getTokenFromLocalStore } from "utils";
import { getUserDetailService } from "services";
import { toast } from "sonner";
import { cleanUserRole } from "utils/general";
import useFetch from "hooks/useFetch";
import Loader from "components/loader";

interface Props {
  open: boolean;
  // handleOk: () => void;
  handleCancel: () => void;
  loading: boolean;
}

const ProfileModal: React.FC<Props> = ({ open, handleCancel, loading }) => {
  const {
    data: photoData,
    fetchData: fetchPhotoData,
    loading: photoLoading,
    error: photoError,
  } = useFetch<any>();
  const {
    data: uploadData,
    fetchData: fetchUploadData,
    loading: uploadLoading,
    error: uploadError,
  } = useFetch<any>();
  const {
    data: userData,
    fetchData: fetchUserData,
    loading: userLoading,
    error: userError,
  } = useFetch<any>();
  const {
    data: personnelData,
    fetchData: fetchPersonnelData,
    loading: personnelLoading,
    error: personnelError,
  } = useFetch<any>();
  const {
    data: updatedData,
    fetchData: fetchUpdatedData,
    loading: updatedLoading,
    error: updatedError,
  } = useFetch<any>();
  const {
    data: userDetilsData,
    fetchData: fetchUserDetails,
    loading: userDetailsLoading,
    error: userDetailsError,
  } = useFetch<any>();
  const [image, setImage] = useState<File | null>(null);
  const data = getTokenFromLocalStore("userInfo") as string;
  const user = JSON.parse(data);
  const { getUserInfo, userInfoState, rolesState, setPersonnelInfo } =
    useAppContext();
  const userInfo = getUserInfo();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [updateData, setUpdateData] = useState({
    username: userInfo?.username || "",
    firstName: userInfo?.firstName || "",
    lastName: userInfo?.lastName || "",
    rolePublicId: "",
    department: userInfo?.department || "",
    email: userInfo?.email || "",
    isPartner: true,
    position: userInfo?.position || "",
    trainingResult: [""],
  });

  useEffect(() => {
    const getRolePublicId = (roleName?: string) => {
      const foundRole = rolesState?.find((role) => role?.name === roleName);
      return foundRole ? foundRole.publicId : null;
    };

    if (user?.roleName && rolesState?.length) {
      const rolePublicId = getRolePublicId(user.roleName);

      setUpdateData((prevData) => {
        if (prevData.rolePublicId !== rolePublicId) {
          return {
            ...prevData,
            rolePublicId: rolePublicId || "",
          };
        }
        return prevData; // Return unchanged data if rolePublicId is the same
      });
    }
  }, [user?.roleName, rolesState]);

  const { setUserInfo, organizationInfo } = useAppContext();

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
      changePhoto(file);
    }
  };
  const rawData = getTokenFromLocalStore("orgDetails") as string;
  const organization = JSON.parse(rawData);
  const changePhoto = (image: File) => {
    if (organizationInfo?.companyName || organization.companyName) {
      fetchUploadData(() =>
        uploadFilesService(
          image,
          organizationInfo === null
            ? organization.companyName
            : organizationInfo?.companyName
        )
      );
    }
  };

  useEffect(() => {
    if (photoData) {
      toast.success("Profile picture updated");
      fetchUserData(() => getUserInfoService(user.publicId));
      handleCancel();
      const params = {
        page: 0,
        size: 10,
      };
      fetchPersonnelData(() => getAllPersonnelService(params));
    }
  }, [photoData]);

  useEffect(() => {
    if (uploadData) {
      fetchPhotoData(() => updateProfilePics(uploadData.data.url));
    }
  }, [uploadData]);

  useEffect(() => {
    if (userData) {
      setUserInfo(userData.data);
    }
  }, [userData]);
  useEffect(() => {
    if (personnelData) {
      setPersonnelInfo(personnelData.data.content);
    }
  }, [personnelData]);

  useEffect(() => {
    if (updatedData) {
      toast.success("Profile details updated successfully");
      handleCancel();
    }
  }, [updatedData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (userDetilsData) {
      setUserInfo(userDetilsData.data);
    }
  }, [setUserInfo, userDetilsData]);

  const update = () => {
    fetchUpdatedData(() => updatePersonnel(updateData, user.publicId));
    const token = getTokenFromLocalStore("access_token") as string;
    fetchUserDetails(() => getUserDetailService(user.publicId, token));
  };
  useEffect(() => {
    if (
      photoError ||
      uploadError ||
      userError ||
      updatedError ||
      userDetailsError ||
      personnelError
    ) {
      toast.error(
        photoError ||
          uploadError ||
          userError ||
          updatedError ||
          userDetailsError ||
          personnelError
      );
    }
  }, [
    photoError,
    uploadError,
    personnelError,
    userError,
    updatedError,
    userDetailsError,
  ]);

  // if (photoLoading || uploadLoading || userLoading) {
  //   return <Loader />;
  // }

  return (
    <Modal
      open={open}
      title="Edit Profile"
      centered
      onOk={update}
      onCancel={handleCancel}
      width={450}
      footer={
        <div className=" w-full flex items-center justify-end gap-6 mt-[2rem] mb-4">
          <button
            className="py-2 px-4 rounded-md bg-white border font-medium text-sm border-[#D7DFE9]"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="py-2 px-4 rounded-md text-white font-medium text-sm bg-[#1B4DFF] border border-[#1B4DFF]"
            key="submit"
            onClick={update}
          >
            {loading ? "Sending" : "Send message"}
          </button>
        </div>
      }
    >
      <p>Send us a message with details of your inquiry.</p>

      <div className="mt-5 ">
        <div className="flex flex-col gap-4">
          {/* avatar */}
          <div className="w-full flex flex-col items-center justify-center">
            {/* profle */}
            <div>
              <div className="w-[80px] aspect-square rounded-full overflow-hidden">
                <img
                  src={userInfo?.profilePicture ?? dummy}
                  alt={userInfo?.username}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            {/* text */}
            <p className="text-sm font-medium text-[#111111] mt-3">
              Upload avatar
            </p>
            <p className="text-sm text-[#455468] my-3">
              Min 400x400px, PNG or JPEG
            </p>

            {/* button */}
            <div className="w-full flex items-center justify-center gap-4">
              <button className=" py-2 px-4 rounded-md text-[#CB1A14] font-medium text-sm bg-[#FFF5F4] border border-[#FFF5F4]">
                Remove
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
              <button
                onClick={handleButtonClick}
                className="w py-2 px-4 rounded-md bg-white border font-medium text-sm border-[#D7DFE9]"
              >
                Change Photo
              </button>
            </div>
          </div>

          {/* full name */}
          <div className="flex items-center gap-4">
            {/* first name */}
            <div>
              <label htmlFor="" className="font-medium text-[#455468] text-sm">
                First name<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <input
                className="w-full border border-[#AFBACA] p-4 rounded-md placeholder:text-[#8897AE] placeholder:text-sm mt-1"
                type="text"
                placeholder="Olamide"
                name="firstName" // Add this line
                value={updateData.firstName}
                onChange={handleInputChange}
              />
            </div>

            {/* last name */}
            <div>
              <label htmlFor="" className="font-medium text-[#455468] text-sm">
                Last name<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <input
                className="w-full border border-[#AFBACA] p-4 rounded-md placeholder:text-[#8897AE] placeholder:text-sm mt-1"
                type="text"
                placeholder="Abiodun"
                name="lastName" // Add this line
                value={updateData.lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* emial */}
          <div>
            <div>
              <label htmlFor="" className="font-medium text-[#455468] text-sm">
                Email<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <div className="flex items-center gap-2 border border-[#AFBACA] p-4 rounded-md mt-1">
                <div>
                  <CustomIcon SvgIcon={email} size={20} />
                </div>
                <input
                  className="w-full placeholder:text-[#8897AE] placeholder:text-sm border-l px-2 bg-transparent outline-none"
                  type="email"
                  placeholder="olamide@gmail.com"
                  name="email" // Add this line
                  value={updateData.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          {/* position */}
          <div>
            <label htmlFor="" className="font-medium text-[#455468] text-sm">
              Position<sup className="text-[#1B4DFF]">*</sup>
            </label>
            <input
              className="w-full border border-[#AFBACA] p-4 rounded-md placeholder:text-[#8897AE] placeholder:text-sm mt-1"
              type="text"
              placeholder="Software Engineer"
              disabled={true}
              name="position" // Add this line
              value={
                updateData.position ||
                cleanUserRole(updateData.rolePublicId || "")
              }
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProfileModal;
