import apiService from "./api";

const permissionurl = process.env.REACT_APP_SHARED_BASE_URL as string;

const getAllRoleService = async () => {
  const url = `/roles`;
  const method = "get";
  return apiService(url, method, null, true, {}, {}, permissionurl);
};
const getRoleByNameService = async (publicId: any) => {
  const url = `/roles/${publicId}`;
  const method = "get";
  return apiService(url, method, null, true, {}, {}, permissionurl);
};
const getRoleGroupByModuleService = async (name: any) => {
  const url = `/roles/grouped-by-module`;
  const method = "get";
  return apiService(url, method, null, true, {}, {}, permissionurl);
};

export { getAllRoleService, getRoleByNameService, getRoleGroupByModuleService };
