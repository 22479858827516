import React from "react";

import { ReactComponent as IconInfo } from "assets/icons/IconInfo.svg";
import { ReactComponent as IconPhone } from "assets/icons/IconPhone.svg";
import { ReactComponent as IconPhoneActive } from "assets/icons/IconPhoneActive.svg";
import { ReactComponent as IconLocation } from "assets/icons/IconLocation.svg";
import { ReactComponent as IconLocationActive } from "assets/icons/IconLocationActive.svg";
import { ReactComponent as IconLink } from "assets/icons/IconLink.svg";
import { ReactComponent as IconLinkActive } from "assets/icons/IconLinkActive.svg";
import { ReactComponent as IconAgreement } from "assets/icons/IconAgreement.svg";
import { ReactComponent as IconAgreementActive } from "assets/icons/IconAgreementActive.svg";

export type OnboardingProcessProps = {
  title: string;
  pathname: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  activeIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const onboardingProcess: OnboardingProcessProps[] = [
  {
    title: "Company information",
    pathname: "/onboarding",
    icon: IconInfo,
    activeIcon: IconInfo,
  },
  {
    title: "Contact information",
    pathname: "/onboarding/details",
    icon: IconPhone,
    activeIcon: IconPhoneActive,
  },
  {
    title: "Address",
    pathname: "/onboarding/address",
    icon: IconLocation,
    activeIcon: IconLocationActive,
  },
  {
    title: "URLs",
    icon: IconLink,
    activeIcon: IconLinkActive,
    pathname: "/onboarding/urls",
  },
  {
    title: "Agreements",
    pathname: "/onboarding/agreements",
    icon: IconAgreement,
    activeIcon: IconAgreementActive,
  },
];
