import apiService from "./api";

const baseurl = process.env.REACT_APP_BASE_URL;

const getAllAssetsService = async (params: {
  page: number;
  size: number;
  riskOwner?: string;
  assetType?: string;
  classification?: string;
  assignedTo?: string;
  name?: string;
}) => {
  const { page, size, assignedTo, assetType, classification, riskOwner, name } =
    params;

  // let url = `/tasks?page=${page}&size=${size}&sort=createdDate%2Cdesc`;
  let url = `/assets?page=${page}&size=${size}&sort=createdDate%2Cdesc`;

  if (assignedTo) {
    url += `&assignedTo=${assignedTo}`;
  }
  if (assetType) {
    url += `&type=${assetType}`;
  }
  if (classification) {
    url += `&classification=${classification}`;
  }
  if (name) {
    url += `&name=${name}`;
  }

  if (riskOwner) {
    url += `&riskOwner=${riskOwner}`;
  }
  const method = "get";

  return apiService(url, method, null, true, {}, {}, baseurl);
};

const getAssetsFilterService = async () => {
  const url = `/assets/distinct`;
  const method = "get";
  return apiService(url, method, null, true, {}, {}, baseurl);
};

const createAssetsService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = `/assets`;
  const method = "post";
  return apiService(url, method, body, true, headers, {}, baseurl);
};

const bulkImportAssetsService = async (file: File) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const body = {
    entity: "ASSETS",
    file,
  };
  const url = `/bulk-import/import`;
  const method = "post";
  return apiService(url, method, body, true, headers, {}, baseurl);
};

const getAssetsByIdService = async (publicId: any) => {
  const url = `/assets/${publicId}`;
  const method = "get";
  return apiService(url, method, null, true, {}, {}, baseurl);
};

const exportAssetAsCsvService = async () => {
  const headers = {
    "Content-Type": "application/octet-stream",
  };
  const url = `export/csv?entity=ASSETS`;
  const method = "get";
  return apiService(url, method, null, true, headers, {}, baseurl, "blob");
};

const deleteAssetsService = async (publicId: any) => {
  const url = `/assets/${publicId}`;
  const method = "delete";
  return apiService(url, method, null, true, {}, {}, baseurl);
};

const updateAssetsService = async (publicId: number, body: any) => {
  const url = `/assets/${publicId}`;
  const method = "put";
  return apiService(url, method, body, true, {}, {}, baseurl);
};

export {
  getAllAssetsService,
  createAssetsService,
  deleteAssetsService,
  getAssetsFilterService,
  getAssetsByIdService,
  updateAssetsService,
  exportAssetAsCsvService,
  bulkImportAssetsService,
};
