import apiService from "./api";

const authurl = process.env.REACT_APP_AUTH_BASE_URL;
const sharedurl = process.env.REACT_APP_SHARED_BASE_URL;
const url = "https://api.vegeel.com/shared";
const OtpUrl = "https://backend.vegeel.com/client";

const loginService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService("/login", "post", body, false, headers, undefined, authurl);
};

const refreshTokenService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return apiService(
    "/refresh",
    "post",
    body,
    false,
    headers,
    undefined,
    authurl
  );
};

const getUserDetailService = async (userId: any, token: any) => {
  const url = `/personnel/${userId}`;
  const baseurl = "https://backend.vegeel.com/client";

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: `Bearer ${token}`,
  };

  return apiService(url, "get", null, true, headers, null, baseurl);
};

const getOrgDetailService = async (orgId: any, token: any) => {
  const url = `/organizations/${orgId}`;
  const baseurl = `${authurl}`;

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: `Bearer ${token}`,
  };
  return apiService(url, "get", null, true, headers, null, baseurl);
};

const resetPasswordService = async (body: any) => {
  if (!body.token) {
    throw new Error("Reset token is missing");
  }

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
    "Reset-Token": encodeURIComponent(body.token),
  };

  return apiService(
    "/auth/reset-password",
    "post",
    body,
    false,
    headers,
    undefined,
    sharedurl
  );
};

const forgetPasswordService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  return apiService(
    "/forgot-password",
    "post",
    body,
    false,
    headers,
    {},
    authurl
  );
};

const generateOtpService = async () => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  return apiService(
    "/auth/generate-totp",
    "get",
    null,
    false,
    headers,
    {},
    url
  );
};

const verifyOtpService = async (body: any) => {
  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  return apiService(
    "/auth/verify-totp",
    "post",
    body,
    false,
    headers,
    {},
    OtpUrl
  );
};

export {
  generateOtpService,
  loginService,
  resetPasswordService,
  verifyOtpService,
  forgetPasswordService,
  refreshTokenService,
  getUserDetailService,
  getOrgDetailService,
};
