import Loader from "components/loader";
import OnboardingContainer from "components/onboarding/container";
import OnboardingHeader from "components/onboarding/header";
import useOnboardingHelpers from "helpers/onboaarding";
import useCloudinaryUpload from "hooks/useCloudinaryUpload";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTokenFromLocalStore } from "utils";

export default function Onboarding() {
  const [isLoading, setIsLoading] = useState(false);

  const { loading, uploadLoading } = useOnboardingHelpers();

  const navigate = useNavigate();

  useEffect(() => {
    const token = getTokenFromLocalStore("access_token") || null;

    if (!token) {
      navigate("/auth");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return;
    }
  }, [navigate]);

  if (loading || uploadLoading || isLoading) {
    return <Loader />;
  }

  return (
    <div className="w-full h-full flex flex-col gap-8">
      <OnboardingHeader />
      <OnboardingContainer />
    </div>
  );
}
