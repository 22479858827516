import apiService from "./api";

const baseurl = process.env.REACT_APP_BASE_URL;

const createAudit = async (body: any) => {
  const url = "/audit-hub/plan";
  const method = "post";
  return apiService(url, method, body, true);
};
const getAudits = async () => {
  const url = "/audit-hub/plan";
  const method = "get";

  return apiService(url, method, {}, true);
};
const editAudit = async (publicId: string) => {
  const url = `/audit-hub/plan/${publicId}`;
  const method = "put";

  return apiService(url, method, {}, true);
};
const getAudit = async (publicId: string) => {
  const url = `/audit-hub/plan/${publicId}`;
  const method = "get";

  return apiService(url, method, {}, true);
};
const deleteAudit = async (publicId: string) => {
  const url = `/audit-hub/plan/${publicId}`;
  const method = "delete";

  return apiService(url, method, {}, true);
};

const createAuditStatus = async (body: any) => {
  const url = "/audit-hub/audit";
  const method = "post";

  return apiService(url, method, body, true);
};
const updateAuditStatus = async (body: any, publicId: string) => {
  const url = `/audit-hub/audit/${publicId}`;
  const method = "put";

  return apiService(url, method, body, true);
};
const getAuditStatus = async () => {
  const url = "/audit-hub/audit";
  const method = "get";

  return apiService(url, method, {}, true);
};

const createAuditFindings = async (publicId: string, body: any) => {
  const url = `/audit-hub/audit/findings?auditPublicId=${publicId}`;
  const method = "post";

  return apiService(url, method, body, true);
};
const getAuditsFindings = async (publicId: string) => {
  const url = `/audit-hub/audit/findings?auditPublicId=${publicId}`;
  const method = "get";

  return apiService(url, method, {}, true);
};

const createAuditReport = async (body: any) => {
  const url = "/audit-hub/report";
  const method = "post";

  return apiService(url, method, body, true);
};
const getAuditEvidence = async (
  publicId: string,
  page: number,
  size: number
) => {
  const url = `/evidencehub?vegeelFrameworkPublicId=${publicId}&status=APPROVED&page=${page}&size=${size}`;
  const method = "get";

  return apiService(url, method, {}, true);
};

export {
  createAudit,
  createAuditStatus,
  getAudits,
  getAuditEvidence,
  updateAuditStatus,
  deleteAudit,
  getAuditsFindings,
  createAuditFindings,
  createAuditReport,
  getAuditStatus,
  editAudit,
  getAudit,
};
