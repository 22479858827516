import React, { createContext, useContext, useState, ReactNode } from "react";

interface FilteredDataContextType {
  filteredData: any[];
  setFilteredData: React.Dispatch<React.SetStateAction<any[]>>;
}

const FilteredDataContext = createContext<FilteredDataContextType | undefined>(
  undefined
);

interface FilteredDataProviderProps {
  children: ReactNode;
}

export const FilteredDataProvider: React.FC<FilteredDataProviderProps> = ({
  children,
}) => {
  const [filteredData, setFilteredData] = useState<any[]>([]);

  return (
    <FilteredDataContext.Provider value={{ filteredData, setFilteredData }}>
      {children}
    </FilteredDataContext.Provider>
  );
};

export const useFilteredData = (): FilteredDataContextType => {
  const context = useContext(FilteredDataContext);
  if (!context) {
    throw new Error(
      "useFilteredData must be used within a FilteredDataProvider"
    );
  }
  return context;
};
