import { Modal } from "antd";
import { ReactComponent as close } from "assets/icons/x-03.svg";
import { ReactComponent as upload } from "assets/icons/upload-01.svg";
import CustomIcon from "components/custom-icon";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { uploadFilesService } from "services";
import { sendHelpService } from "services/help";
import { toast } from "sonner";
import Loader from "components/loader";

interface Props {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  loading: boolean;
}

const HelpModal: React.FC<Props> = ({
  open,
  handleOk,
  handleCancel,
  loading,
}) => {
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  const {
    data: sendHelpData,
    error: sendHelpError,
    loading: sendHelpLoading,
    fetchData: sendHelpFetchData,
  } = useFetch<any>();

  const {
    data: uploadPictureData,
    error: uploadPictureError,
    loading: uploadPictureLoading,
    fetchData: uploadPictureFetchData,
  } = useFetch<any>();

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
    }
  };

  const handleRemoveImage = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const sendHelp = () => {
    if (!title || !message) {
      toast.error("Please fill in both title, message.");
      return;
    }

    if (selectedFiles.length) {
      selectedFiles.forEach((file) => {
        const fileExtension = file.name.split(".").pop();
        if (fileExtension) {
          uploadPictureFetchData(() => uploadFilesService(file, fileExtension));
        }
      });
    } else {
      sendHelpFetchData(() =>
        sendHelpService({
          title,
          message,
          imageUrl: uploadedImages,
        })
      );
    }
  };

  useEffect(() => {
    if (sendHelpData) {
      toast.success("Help Request Sent Successfully");
    }
  }, [sendHelpData]);

  useEffect(() => {
    if (uploadPictureData) {
      const secureUrl = uploadPictureData?.data?.secure_url;
      if (secureUrl) {
        setUploadedImages((prevImages) => [...prevImages, secureUrl]);
      }
    }
  }, [uploadPictureData]);

  useEffect(() => {
    if (
      uploadedImages.length === selectedFiles.length &&
      uploadedImages.length > 0
    ) {
      sendHelpFetchData(() =>
        sendHelpService({
          title,
          message,
          imageUrl: uploadedImages,
        })
      );
    }
  }, [uploadedImages]);

  useEffect(() => {
    if (uploadPictureError || sendHelpError) {
      toast.error(uploadPictureError || sendHelpError);
    }
  }, [uploadPictureError, sendHelpError]);

  if (uploadPictureLoading || sendHelpLoading) {
    return <Loader />;
  }

  return (
    <Modal
      open={open}
      title="Need help?"
      centered
      onOk={handleOk}
      onCancel={handleCancel}
      width={600}
      footer={
        <div className="w-full flex items-center gap-6 mt-[2rem] mb-4">
          <button
            className="w-full py-2 rounded-md bg-white border font-medium text-sm border-[#D7DFE9]"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="w-full py-2 rounded-md text-white font-medium text-sm bg-[#1B4DFF] border border-[#1B4DFF]"
            key="submit"
            onClick={sendHelp}
          >
            {loading ? "Sending" : "Send message"}
          </button>
        </div>
      }
    >
      <p>Send us a message with details of your inquiry.</p>

      <div className="mt-5">
        <form className="flex flex-col gap-4">
          <div>
            <label htmlFor="message" className="font-medium text-sm">
              Title<sup className="text-[#1B4DFF]">*</sup>
            </label>
            <textarea
              name="title"
              id="title"
              className="w-full bg-white rounded-md p-2.5 placeholder:text-[#8897AE] mt-1 h-[30px] border border-[#AFBACA] resize-none flex justify-center items-start"
              placeholder="Enter your title..."
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></textarea>
          </div>
          {/* Message */}
          <div>
            <label htmlFor="message" className="font-medium text-sm">
              Message<sup className="text-[#1B4DFF]">*</sup>
            </label>
            <textarea
              name="message"
              id="message"
              className="w-full bg-white rounded-md p-2.5 placeholder:text-[#8897AE] mt-1 h-[130px] border border-[#AFBACA] resize-none"
              placeholder="Describe your need..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>

          {/* Upload Image */}
          <div>
            <p className="font-medium text-sm mb-1">Add screenshots</p>
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-[46px] border border-[#AFBACA] rounded-md"
              >
                <div className="flex items-center gap-4 justify-center pt-5 pb-6">
                  <div className="flex items-center justify-center w-7 aspect-square rounded-full bg-[#F2F5FF]">
                    <CustomIcon SvgIcon={upload} size={18} />
                  </div>
                  <p className="text-sm text-[#455468]">
                    Drag and drop here or{" "}
                    <span className="font-bold text-[#042BB2]">
                      browse files
                    </span>
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          </div>
        </form>

        {/* Display Selected Images */}
        <div className="flex items-center gap-4 relative mt-4">
          {selectedFiles.map((file, index) => (
            <div key={index} className="relative">
              <div className="w-[60px] aspect-square rounded-md overflow-hidden">
                <img
                  src={URL.createObjectURL(file)}
                  className="h-full w-full object-cover"
                  alt={`Selected ${index}`}
                />
              </div>
              <div
                className="w-5 aspect-square rounded-full flex items-center justify-center absolute -right-2 -top-1.5 bg-white cursor-pointer"
                onClick={() => handleRemoveImage(index)}
              >
                <CustomIcon SvgIcon={close} size={16} />
              </div>
            </div>
          ))}
        </div>
        <hr className="w-full bg-[#D7DFE9] my-7" />
      </div>
    </Modal>
  );
};

export default HelpModal;
