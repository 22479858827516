import React, { useEffect, useState } from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { PathProps, SidebarCenterItem } from "types";
import { useSidebarContext } from "context/sidebar";
import ExtraSidebar from "components/sidebar/extra";
import { getTokenFromLocalStore } from "utils";
import Loader from "components/loader";
import { useAppContext, useFrameworkContext } from "context";
import { useAuth } from "context/auth";

const Authenticated: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const { sidebarData } = useSidebarContext();
  const { rolesLoading } = useAppContext();
  const { allFrameworksLoading } = useFrameworkContext();
  // const { isAuthenticated } = useAuth();
  const currentPath = location.pathname;

  useEffect(() => {
    const token = getTokenFromLocalStore("access_token") || null;

    if (!token) {
      navigate("/auth");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return;
    }
  }, [navigate]);

  const extraSidebarItem = sidebarData.find((sidebarItem: SidebarCenterItem) =>
    sidebarItem.parentTitle.paths.some(
      (path: PathProps) =>
        currentPath.startsWith(path.link) &&
        path.shouldOpenSidebar &&
        path.sideBarPaths
    )
  );

  const extraSidebarPaths: any[] | null = extraSidebarItem
    ? extraSidebarItem.parentTitle.paths.find(
        (path: PathProps) =>
          currentPath.startsWith(path.link) &&
          path.shouldOpenSidebar &&
          path.sideBarPaths
      )?.sideBarPaths ?? null
    : null;

  if (isLoading || rolesLoading || allFrameworksLoading) {
    return <Loader />;
  }

  return (
    <div className="h-full w-full flex">
      <Sidebar />
      {extraSidebarPaths && (
        <ExtraSidebar extraSidebarPaths={extraSidebarPaths} />
      )}
      <div
        className={`${
          extraSidebarPaths ? "w-[66%]" : "w-[83%]"
        }  bg-[#FAFBFC] `}
      >
        <Header />
        <div className="w-full px-10 h-[calc(100%-70px)]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Authenticated;
