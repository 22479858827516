import React, { createContext, useContext, useState, ReactNode } from "react";

interface UploadContextType {
  documentName: any;
  classification: any;
  reviewCycle: any;
  owner: any;
  version: any;
  approver: any;
  documentUrl: any;
  vegeelFrameworkPublicIds: any;
  setDocumentName: any;
  setClassification: any;
  setReviewCycle: any;
  setOwner: any;
  setVersion: any;
  setApprover: any;
  setDocumentUrl: any;
  setVegeelFrameworkPublicIds: any;
}

const UploadContext = createContext<UploadContextType | undefined>(undefined);

export const UploadProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [documentName, setDocumentName] = useState("");
  const [classification, setClassification] = useState("");
  const [reviewCycle, setReviewCycle] = useState("");
  const [owner, setOwner] = useState("");
  const [version, setVersion] = useState("");
  const [approver, setApprover] = useState("");
  const [documentUrl, setDocumentUrl] = useState<string | null>(null);
  const [vegeelFrameworkPublicIds, setVegeelFrameworkPublicIds] = useState<
    string[]
  >([]);

  return (
    <UploadContext.Provider
      value={{
        documentName,
        classification,
        reviewCycle,
        owner,
        version,
        approver,
        documentUrl,
        vegeelFrameworkPublicIds,
        setDocumentName,
        setClassification,
        setReviewCycle,
        setOwner,
        setVersion,
        setApprover,
        setVegeelFrameworkPublicIds,
        setDocumentUrl,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (!context) {
    throw new Error("useUploadContext must be used within an UploadProvider");
  }
  return context;
};
