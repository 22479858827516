import { useLocation } from "react-router-dom";
import useOnboardingHelpers from "helpers/onboaarding";
// import OnboardingButton from "components/";
import CustomIcon from "components/custom-icon";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle-contained.svg";
import OnboardingButton from "components/onboarding/button";
import { useOnboardingContext } from "hooks/useOnboarding";

const OnboardingHeader = () => {
  const { pathname } = useLocation();
  const { handleBack, handleNextPage, handleSubmit, loading } =
    useOnboardingHelpers();

  const { aggreement } = useOnboardingContext();

  return (
    <div className="h-[96px] flex flex-col justify-between">
      <div className="h-[36px] flex justify-between">
        <div className="bg-[#F2F5FF] px-[12px] py-[6px] rounded-md flex justify-center gap-[10px] items-center">
          <span className="text-[#1B4DFF] text-sm leading-[24px] font-medium ">
            Onboarding
          </span>
          <CustomIcon SvgIcon={HelpCircle} size={18} />
        </div>
        <div className="flex gap-2">
          {pathname !== "/onboarding" && (
            <OnboardingButton
              label="back"
              icon
              disabled={false}
              variant="outline"
              onClick={handleBack}
            />
          )}

          {pathname !== "/onboarding/agreements" && (
            <OnboardingButton
              label="next"
              disabled={false}
              icon
              variant="contained"
              onClick={handleNextPage}
            />
          )}

          {pathname === "/onboarding/agreements" && (
            <OnboardingButton
              label="Done"
              icon
              disabled={!aggreement || loading}
              variant="contained"
              onClick={() => handleSubmit()}
            />
          )}
        </div>
      </div>
      <div className="h-[49px] flex flex-col gap-[8px]">
        <h1 className="text-[#111111] font-bold leading-[30px] tracking-[-0.3px] text-lg">
          Company details
        </h1>
        <p className="text-[#455468] text-sm font-normal tracking-[-0.2px]">
          Complete all steps to begin compliance management
        </p>
      </div>
    </div>
  );
};

export default OnboardingHeader;
