import React, {
  ReactNode,
  createContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { getTokenFromLocalStore, saveKeyToLocalStore } from "utils";

interface GeneralInfo {
  auditClient: string;
  date: string;
  auditType: string;
  managementSystem: string;
  openingMeeting: string;
  closingMeeting: string;
  auditScope: string;
  auditObjectives: string;
  auditCriteria: string;
  hasCompleted: boolean;
}

interface TimeSlot {
  date: string;
  timeFrom: string;
  timeTo: string;
  auditee: string[];
  auditor: string[];
  auditArea: string;
}

interface Day {
  dayNumber: number;
  timeSlots: TimeSlot[];
  hasCompleted: boolean;
}

interface AuditorTeam {
  teamMembers: {
    firstName: string;
    lastName: string;
    assignedRole: string;
    hasCompleted: boolean;
  }[];
  hasCompleted: boolean;
}

// interface AuditTimetable {
//   date: string;
//   timeFrom: string;
//   timeTo: string;
//   auditee: string; //will be an array
//   auditor: string; //will be an array
//   hasCompleted: boolean;
// }

export interface AudithubContextType {
  generalInfo: GeneralInfo;
  auditorTeam: AuditorTeam;
  auditTimetable: Day[];
  clearContext: any;
  setCreateauditForm: Dispatch<SetStateAction<AudithubContextType>>;
}

const initialAudithubState: AudithubContextType = {
  generalInfo: {
    auditClient: "",
    date: "",
    auditType: "",
    managementSystem: "",
    openingMeeting: "",
    closingMeeting: "",
    auditScope: "",
    auditObjectives: "",
    auditCriteria: "",
    hasCompleted: false,
  },
  auditorTeam: {
    teamMembers: [],
    hasCompleted: false,
  },
  auditTimetable: [1, 2].map((dayNumber) => ({
    dayNumber,
    hasCompleted: false,
    timeSlots: [],
  })),
  clearContext: () => {},
  setCreateauditForm: () => {},
};

export const AudithubContext = createContext<AudithubContextType | undefined>(
  undefined
);

export const AudithubProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [audithubForm, setAudithubForm] = useState<AudithubContextType>(() => {
    const savedState = getTokenFromLocalStore<string>("audithubForm");
    if (savedState) {
      try {
        const data: AudithubContextType = JSON.parse(savedState);
        return data || initialAudithubState;
      } catch (error) {
        console.error("Error parsing saved audithub state:", error);
      }
    }
    return initialAudithubState;
  });

  useEffect(() => {
    saveKeyToLocalStore("audithubForm", JSON.stringify(audithubForm));
  }, [audithubForm]);
  const clearContext = () => {
    setAudithubForm(initialAudithubState); // Resetting to default
  };
  useEffect(() => {
    const validateCompletion = () => {
      // Check if all fields in GeneralInfo are filled
      const isAudithubComplete =
        audithubForm.generalInfo &&
        Object.values(audithubForm.generalInfo).every(
          (value) => value !== "" && value !== null
        );

      // Check if all fields for each auditor in the team are filled
      const isAuditorTeamComplete =
        audithubForm.auditorTeam &&
        audithubForm.auditorTeam.teamMembers.every((teamMember) =>
          Object.values(teamMember).every((value) => value !== "")
        );

      // Check if all timeslots for each day in the timetable are filled
      const isAuditTimetableComplete =
        audithubForm.auditTimetable &&
        audithubForm.auditTimetable.every((timetable) =>
          timetable.timeSlots.every(
            (slot) =>
              slot.timeFrom !== "" &&
              slot.timeTo !== "" &&
              slot.auditee.length > 0 &&
              slot.auditor.length > 0
          )
        );

      setAudithubForm((prev) => {
        const updatedForm = {
          ...prev,
          generalInfo: {
            ...prev.generalInfo,
            hasCompleted: isAudithubComplete,
          },
          auditorTeam: {
            ...prev.auditorTeam,
            hasCompleted: isAuditorTeamComplete, // General completion status for the entire team
          },
          auditTimetable: prev.auditTimetable.map((timetable) => ({
            ...timetable,
            hasCompleted: isAuditTimetableComplete, // Completion status for each day
          })),
        };

        if (JSON.stringify(prev) === JSON.stringify(updatedForm)) {
          return prev; // No changes detected, return previous state
        }

        return updatedForm; // Return the updated form state
      });
    };

    validateCompletion();
  }, [audithubForm]);

  return (
    <AudithubContext.Provider
      value={{
        ...audithubForm,
        clearContext,
        setCreateauditForm: setAudithubForm,
      }}
    >
      {children}
    </AudithubContext.Provider>
  );
};
