import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getTokenFromLocalStore } from "utils";
import { UserRole } from "context"; // Assuming UserRole is a string

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  allowedRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  allowedRoles,
  ...rest
}) => {
  const [userRole, setUserRole] = useState<UserRole | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userRoleFromStorage = getTokenFromLocalStore<string>("userRole");
    if (userRoleFromStorage) {
      setUserRole(JSON.parse(userRoleFromStorage) as UserRole);
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <></>;
  }

  const hasAccess = userRole ? allowedRoles.includes(userRole) : false;

  return hasAccess ? <Component {...rest} /> : <Navigate to="/unauthorized" />;
};

export default ProtectedRoute;
