import { useState } from "react";

function useModal() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => setOpen(true);

  const handleOk = (onOkCallback?: () => void) => {
    setOpen(false);
    if (typeof onOkCallback === "function") {
      onOkCallback();
    } else {
      console.error("onOkCallback is not a function");
    }
  };

  const handleCancel = () => setOpen(false);

  return { open, loading, showModal, handleOk, handleCancel, setOpen };
}

export default useModal;
