import { Dropdown, Menu, MenuProps } from "antd";
import { ReactComponent as IconBell } from "assets/icons/Iconbell.svg";
import { ReactComponent as IconDrowdown } from "assets/icons/IconDropdown.svg";
import { ReactComponent as IconExit } from "assets/icons/Iconexist.svg";
import { ReactComponent as IconLock } from "assets/icons/IconLock.svg";
import { ReactComponent as IconLogout } from "assets/icons/IconLogout.svg";
import { ReactComponent as IconUser } from "assets/icons/IconUserProfile.svg";
import { ReactComponent as max } from "assets/icons/maximise-01.svg";
import { ReactComponent as close } from "assets/icons/x-01.svg";
import dummy from "assets/images/dummy.png";
import CustomIcon from "components/custom-icon";
import Loader from "components/loader";
import { useAppContext } from "context/home/app";
import { useHeaderContext } from "context/home/header";
import useModal from "helpers/useModal-hook";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getNotificationsNotRead } from "services";
import { toast } from "sonner";
import { INotificationItem, INotificationResponse } from "types/notifications";
import HelpModal from "./help-modal";
import LogoutModal from "./logout-modal";
import NotifyItems from "./notify-items";
import { theme } from "antd";
import PasswordModal from "./password-modal";
import ProfileModal from "./profle-modal";
import React from "react";

export default function Header() {
  const { headerTitle } = useHeaderContext();
  const [notifications, setNotifications] = useState<INotificationItem[]>([]);
  const { getUserInfo, getOrganizationInfo } = useAppContext();
  const { open, loading, showModal, handleOk, handleCancel } = useModal();
  const userInfo = getUserInfo();
  const {
    open: open1,
    loading: loading1,
    showModal: showModal1,
    handleOk: handleOk1,
    handleCancel: handleCancel1,
  } = useModal();

  const {
    open: open2,
    loading: loading2,
    showModal: showModal2,
    handleOk: handleOk2,
    handleCancel: handleCancel2,
  } = useModal();

  const {
    open: open3,
    loading: loading3,
    showModal: showModal3,
    handleOk: handleOk3,
    handleCancel: handleCancel3,
  } = useModal();

  const {
    data: notificationsData,
    loading: notificationsLoading,
    error: notificationsError,
    fetchData: fetchNotifications,
  } = useFetch<INotificationResponse>();

  useEffect(() => {
    fetchNotifications(() => getNotificationsNotRead());
  }, []);

  useEffect(() => {
    if (notificationsData) {
      setNotifications(notificationsData.data.content);
    }
  }, [notificationsData]);

  const items: MenuProps["items"] = [
    {
      label: (
        <div className="py-1 pr-16" onClick={showModal1}>
          <span className="font-medium pl-1 leading-[24px] text-sm text-[#455468]">
            View Profile
          </span>
        </div>
      ),
      icon: <CustomIcon SvgIcon={IconUser} size={16} />,
      key: "0",
    },
    {
      label: (
        <div className="py-1 pl-1 pr-16" onClick={showModal2}>
          <span className="font-medium leading-[24px]  text-sm text-[#455468]">
            Change Password
          </span>
        </div>
      ),
      icon: <CustomIcon SvgIcon={IconLock} size={16} />,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          className="flex items-center justify-between w-full py-1 px-2"
          onClick={showModal3}
        >
          <span className="font-medium leading-[24px]  text-sm text-[#CB1A14]">
            Logout
          </span>
          <p className="text-[#667085] text-xs">v1.0</p>
        </div>
      ),
      icon: <CustomIcon SvgIcon={IconLogout} size={16} />,
      key: "2",
    },
  ];

  const notificationsDropdown: MenuProps["items"] = [
    {
      label: (
        <div className="w-full flex items-center justify-between">
          <span className="font-medium pl-1 leading-[24px] text-sm text-[#455468]">
            Notifications
          </span>

          <div className="flex items-center gap-2 ">
            <p className="text-xs text-[#1B4DFF] font-medium rounded-md px-2 bg-[#E5EAFC]">
              {notificationsData?.data.page.totalElements} unread
            </p>
            <Link to="/notifications">
              <div>
                <CustomIcon SvgIcon={max} size={16} />
              </div>
            </Link>
            <button>
              <div>
                <CustomIcon SvgIcon={close} size={16} />
              </div>
            </button>
          </div>
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    ...notifications.map((notification, index) => ({
      key: `notification-${index}`,
      label: (
        <Menu.Item
          key={index}
          style={{
            background: "transparent",
          }}
        >
          <NotifyItems
            avatar={notification.senderId}
            title={notification.subject}
            audit={notification.attachmentUrls}
            attachments={notification.attachmentUrls}
            date={notification.createdDate}
          />
        </Menu.Item>
      ),
    })),
  ];
  const { useToken } = theme;

  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  useEffect(() => {
    if (notificationsError) {
      toast.error(notificationsError);
    }
  }, [notificationsError]);

  if (notificationsLoading) {
    return <Loader />;
  }

  return (
    <header className="h-[70px] w-full flex items-center justify-between px-10 border-b border-[#D7DFE9]">
      <h1 className="text-[24px] font-medium leading-[36px] tracking-[0.4px] text-[#111111]">
        {headerTitle}
      </h1>

      <div className="h-[36px] flex justify-between items-center gap-4">
        <Dropdown
          menu={{ items: notificationsDropdown }}
          trigger={["click"]}
          dropdownRender={(menu) => (
            <div className="h-[400px] overflow-scroll">
              {React.cloneElement(menu as React.ReactElement)}
            </div>
          )}
          // className="min-h-[300px]"
        >
          <div className="h-full w-[36px] bg-[#E5EAFC] flex justify-center items-center rounded-md cursor-pointer">
            <CustomIcon SvgIcon={IconBell} size={18} />
          </div>
        </Dropdown>
        <button
          className="h-[24px] w-[56px] flex justify-center items-center gap-[10px]"
          onClick={showModal}
        >
          <span className="text-sm text-[#111111] font-medium leading-[24px] ">
            Help
          </span>
          <CustomIcon SvgIcon={IconExit} size={12} />
        </button>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <div className="py-[4px] px-[10px] bg-[#F2F5FF] border border-[#E5EAFC] rounded-[100px] flex items-center gap-1 cursor-pointer">
            <img
              src={userInfo?.profilePicture ?? dummy}
              alt={userInfo?.username}
              className="w-[28px] h-[28px] object-cover rounded-full"
            />
            <CustomIcon SvgIcon={IconDrowdown} size={10} />
          </div>
        </Dropdown>
      </div>

      <HelpModal
        open={open}
        loading={loading}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />

      <ProfileModal
        open={open1}
        loading={loading1}
        // handleOk={handleOk1}
        handleCancel={handleCancel1}
      />

      <PasswordModal
        open={open2}
        loading={loading2}
        // handleOk={handleOk2}
        handleCancel={handleCancel2}
      />

      <LogoutModal
        open={open3}
        loading={loading3}
        handleOk={handleOk3}
        handleCancel={handleCancel3}
      />
    </header>
  );
}
