import error from "assets/images/404.svg";
import { Link, useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const dashboard = () => {
    navigate("/");
  };

  return (
    <div className="w-full h-full gradient px-8">
      <div className="w-full h-full flex items-center justify-center gap-10 pt-10">
        <div className=" w-full flex justify-end">
          <div className="w-[429px]">
            <h1 className="text-[#1B4DFF] text-[56px] font-semibold">
              Oops!!!
            </h1>
            <p className=" text-black text-[36px] font-semibold">
              Page not found
            </p>
            <p className="text-sm text-[#455468] font-medium pt-3 pb-5">
              It seems you have stumbled upon a missing page, or another error
              occurred. But don’t worry, even the best have a slip-up now and
              then. Go back to dashboard.
            </p>
            <button
              className="py-2 px-[15px] w-fits border border-[#B4C4FF] bg-[#1B4DFF] text-white rounded-md "
              onClick={dashboard}
            >
              Go to dashboard
            </button>
          </div>
        </div>
        <div className="w-full ">
          <div className="">
            <img src={error} alt="error page" className="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
