import React, { useState, useRef, useEffect } from "react";
import boldAlphbt from "assets/icons/bold-alphabet.svg";
import italicAlphbt from "assets/icons/italic-alpahbet.svg";
import quoteIcon from "assets/icons/douvleQuote-alphabet.svg";
import underlineAlphbt from "assets/icons/underline-01.svg";
import AtIcon from "assets/icons/at-sign.svg";

interface Props {
  onSend: (content: string) => void; // Callback function prop
}

export const TextEditor: React.FC<Props> = ({ onSend }) => {
  const editorRef = useRef<HTMLDivElement>(null);

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const applyTextStyle = (command: string) => {
    document.execCommand(command, false, "");
    updateButtonStates();
    editorRef.current?.focus(); // Keep the focus on the editor
  };

  const updateButtonStates = () => {
    setIsBold(document.queryCommandState("bold"));
    setIsItalic(document.queryCommandState("italic"));
    setIsUnderline(document.queryCommandState("underline"));
  };

  const insertAtSymbol = () => {
    document.execCommand("insertText", false, "@");
    editorRef.current?.focus();
  };

  const handleSend = () => {
    const content = editorRef.current?.innerHTML;
    if (content && onSend) {
      onSend(content); // Send the inner HTML as the content
      editorRef.current.innerHTML =
        "<p class='placeholder text-[#8897AE] mt-3 font-[500] text-[14px]'>Enter a caption...</p>";
    }
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.innerHTML =
        "<p class='placeholder text-[#8897AE] mt-3 font-[500] text-[14px]'>Enter a caption...</p>";
    }
  }, []);

  return (
    <div className="w-full h-[140px] border bg-white border-b-[1px] border-slate-300 rounded-md p-2">
      <div
        ref={editorRef}
        contentEditable
        onFocus={() => {
          if (editorRef.current?.innerText === "Enter a caption...") {
            editorRef.current.innerHTML = "";
          }
        }}
        onBlur={() => {
          if (editorRef.current?.innerText.trim() === "") {
            editorRef.current.innerHTML =
              "<p class='placeholder text-[#8897AE] mt-3 font-[500] text-[14px]'>Enter a caption...</p>";
          }
        }}
        onKeyUp={updateButtonStates} // Update states on keyup to reflect changes
        onMouseUp={updateButtonStates} // Update states on mouse selection
        className="w-full h-[80px] bg-white border-b-[1px] border-slate-300 outline-none p-2 overflow-auto"
      ></div>

      <div className="flex my-2 justify-between items-center">
        <div className="flex w-1/2">
          <img
            src={boldAlphbt}
            alt="Bold"
            onClick={() => applyTextStyle("bold")}
            className={`pr-2 py-1 cursor-pointer ${
              isBold ? "bg-gray-300" : ""
            }`}
          />
          <img
            src={italicAlphbt}
            alt="Italic"
            onClick={() => applyTextStyle("italic")}
            className={`px-2 py-1 cursor-pointer ${
              isItalic ? "bg-gray-300" : ""
            }`}
          />
          <img
            src={quoteIcon}
            alt="Quote"
            onClick={() => applyTextStyle("formatBlock")}
            className="px-2 py-1 cursor-pointer"
          />
          <img
            src={underlineAlphbt}
            alt="Underline"
            onClick={() => applyTextStyle("underline")}
            className={`px-2 py-1 cursor-pointer ${
              isUnderline ? "bg-gray-300" : ""
            }`}
          />
        </div>

        <div className="flex justify-end items-center w-1/2">
          <img
            src={AtIcon}
            alt="@"
            onClick={insertAtSymbol}
            className="px-2 py-1 cursor-pointer"
          />
          <button
            onClick={handleSend}
            className="py-[6px] px-[12px] bg-[#1B4DFF] rounded-[6px] flex justify-center items-center gap-[10px] text-white ml-2"
          >
            <h1 className="font-[500] text-[14px] leading-[24px]">Send</h1>
          </button>
        </div>
      </div>
    </div>
  );
};
