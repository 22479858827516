import { Checkbox as Check, CheckboxProps, Modal } from "antd";
import { ReactComponent as lock } from "assets/icons/lock-open-01.svg";
import { ReactComponent as email } from "assets/icons/email.svg";
import { ReactComponent as off } from "assets/icons/off.svg";
import img from "assets/images/uk.jpg";
import img2 from "assets/images/text2.png";
import CustomIcon from "components/custom-icon";
import { resetPasswordService } from "services";
import Loader from "components/loader";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { InputRest } from "components/authenticatons/common";
import Checkbox from "components/authenticatons/checkBox";
import { getTokenFromLocalStore } from "utils";
interface Props {
  open: boolean;
  // handleOk: () => void;
  handleCancel: () => void;
  loading: boolean;
}

interface PasswordFieldProps {
  label: string;
  name: any;
  handleOnChange: (e: any) => void;
  handleOnBlur: (e: any) => void;
  value: any;
  error: any;
  touched: any;
}

const PasswordField = ({
  label,
  handleOnChange,
  name,
  value,
  error,
  handleOnBlur,
  touched,
}: PasswordFieldProps) => {
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    handleOnChange(e);
    setHasUppercase(/[A-Z]/.test(newPassword));
    setHasLowercase(/[a-z]/.test(newPassword));
    setHasNumber(/\d/.test(newPassword));
    setHasSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(newPassword));
  };

  return (
    <div className="">
      <InputRest
        name={name}
        value={value}
        label={label}
        type="password"
        textSize="base"
        placeholder="New Password"
        onChange={handlePasswordChange}
        onBlur={handleOnBlur}
        error={error === "checks" ? undefined : error}
        touched={touched}
      />
      <p className={`text-[14px] px-1 text-bottomText text-[#5E718D] `}>
        Make sure your password is 8 or more characters and has the following
      </p>
      <div className="mx-2 my-4 space-y-3 items-center gap-4">
        <Checkbox
          label="An uppercase letter"
          checked={hasUppercase}
          disabled
          error={error === "checks" && !hasUppercase}
        />
        <Checkbox
          label="A lowercase letter"
          checked={hasLowercase}
          disabled
          error={error === "checks" && !hasLowercase}
        />
        <Checkbox
          label="A number"
          checked={hasNumber}
          disabled
          error={error === "checks" && !hasNumber}
        />
        <Checkbox
          label="A special character"
          checked={hasSpecialChar}
          disabled
          error={error === "checks" && !hasSpecialChar}
        />
      </div>
    </div>
  );
};

const PasswordModal: React.FC<Props> = ({
  open,
  // handleOk,
  handleCancel,
  loading,
}) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<{
    newPassword?: string;
    confirmPassword?: string;
    currentPassword?: string;
  }>({});
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  interface INewPassword {
    type?: any;
    setStep?: any;
  }

  interface ISetpassword {
    isError: boolean;
    isSuccess: boolean;
    status: string;
    timestamp: string;
    description: string;
  }
  const { data, error, fetchData, loading: load } = useFetch<ISetpassword>();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    validateForm();
  }, [newPassword, confirmPassword, currentPassword]);

  useEffect(() => {
    if (data) {
      toast.success("successfully");
      navigate("/auth");
    }
  }, [data, navigate]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const validateForm = () => {
    const newErrors: {
      newPassword?: string;
      confirmPassword?: string;
      currentPassword?: string;
    } = {};

    if (!currentPassword) {
      newErrors.currentPassword = "Current password is required";
    }
    // Validate newPassword
    if (!newPassword) {
      newErrors.newPassword = "New password is required";
    } else if (newPassword.length < 8) {
      newErrors.newPassword = "Password must be at least 8 characters long";
    } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(newPassword)) {
      newErrors.newPassword =
        "Password must contain at least one uppercase letter, one lowercase letter, and one number";
    }

    // Validate confirmPassword
    if (!confirmPassword) {
      newErrors.confirmPassword = "Confirm password is required";
    } else if (confirmPassword !== newPassword) {
      newErrors.confirmPassword = "Passwords must match";
    }

    setErrors(newErrors);
    setBtnDisabled(Object.keys(newErrors).length > 0);
  };

  const handleSubmit = async () => {
    const token = getTokenFromLocalStore("access_token");
    const body = {
      token: token,
      password: newPassword,
      confirmPassword: confirmPassword,
    };
  };

  if (load) {
    return <Loader />;
  }
  return (
    <Modal
      open={open}
      title="Change Password"
      centered
      // onOk={handleSubmit}
      onCancel={handleCancel}
      width={450}
      footer={
        <div className=" w-full flex items-center justify-end gap-6 mt-[2rem] mb-4">
          <button
            className="py-2 px-4 rounded-md bg-white border font-medium text-sm border-[#D7DFE9]"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="py-2 px-4 rounded-md text-white font-medium text-sm bg-[#1B4DFF] border border-[#1B4DFF]"
            key="submit"
            onClick={handleSubmit}
          >
            {/* {loading ? "Sending" : "Save changes"} */}
            Save changes
          </button>
        </div>
      }
    >
      <div className="mt-5 ">
        {/* <form className="flex flex-col gap-4">
          password
          <div>
            <div>
              <label htmlFor="" className="font-medium text-[#455468] text-sm">
                Current password<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <div className="flex items-center gap-2 border border-[#AFBACA] p-4 rounded-md mt-1">
                <div>
                  <CustomIcon SvgIcon={lock} size={20} />
                </div>
                <input
                  className="w-full placeholder:text-[#8897AE] placeholder:text-sm border-l px-2 bg-transparent outline-none"
                  type="password"
                  placeholder="Password"
                />
                <div>
                  <CustomIcon SvgIcon={off} size={20} />
                </div>
              </div>
            </div>
          </div>

          new password
          <div>
            <div>
              <label htmlFor="" className="font-medium text-[#455468] text-sm">
                New password<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <div className="flex items-center gap-2 border border-[#AFBACA] p-4 rounded-md mt-1">
                <div>
                  <CustomIcon SvgIcon={lock} size={20} />
                </div>
                <input
                  className="w-full placeholder:text-[#8897AE] placeholder:text-sm border-l px-2 bg-transparent outline-none"
                  type="password"
                  placeholder="Password"
                />
                <div>
                  <CustomIcon SvgIcon={off} size={20} />
                </div>
              </div>
            </div>
          </div>

          details check
          <div>
            <p className="text-sm text-[#455468]">
              Make sure your new password is 8 or more characters and has the
              following
            </p>

            <div className="flex flex-col gap-4 mt-4">
              <div className="flex items-center gap-4">
                checkbox
                <Checkbox onChange={onChange} />
                <p className=" text-[#455468] text-sm">An uppercase letter</p>
              </div>
              <div className="flex items-center gap-4">
                checkbox
                <Checkbox onChange={onChange} />
                <p className=" text-[#455468] text-sm">An lowercase letter</p>
              </div>
              <div className="flex items-center gap-4">
                checkbox
                <Checkbox onChange={onChange} />
                <p className=" text-[#455468] text-sm">An number</p>
              </div>
              <div className="flex items-center gap-4">
                checkbox
                <Checkbox onChange={onChange} />
                <p className=" text-[#455468] text-sm">A special character</p>
              </div>
            </div>
          </div>

          confirm password
          <div>
            <div>
              <label htmlFor="" className="font-medium text-[#455468] text-sm">
                Confirm password<sup className="text-[#1B4DFF]">*</sup>
              </label>
              <div className="flex items-center gap-2 border border-[#AFBACA] p-4 rounded-md mt-1">
                <div>
                  <CustomIcon SvgIcon={lock} size={20} />
                </div>
                <input
                  className="w-full placeholder:text-[#8897AE] placeholder:text-sm border-l px-2 bg-transparent outline-none"
                  type="password"
                  placeholder="Password"
                />
                <div>
                  <CustomIcon SvgIcon={off} size={20} />
                </div>
              </div>
            </div>
          </div>

        </form> */}

        <div>
          <InputRest
            value={currentPassword}
            label={"Current password"}
            type="password"
            name="currentPassword"
            placeholder="Password"
            textSize="base"
            onChange={(e) => setCurrentPassword(e.target.value)}
            onBlur={validateForm}
            error={errors.currentPassword}
            touched={!!errors.currentPassword}
          />
        </div>

        <form>
          <PasswordField
            error={errors.newPassword}
            label="New Password"
            name="newPassword"
            value={newPassword}
            handleOnChange={(e) => setNewPassword(e.target.value)}
            handleOnBlur={validateForm}
            touched={!!errors.newPassword}
          />
          <div className="py-2">
            <InputRest
              value={confirmPassword}
              label={"Confirm password"}
              type="password"
              name="confirmPassword"
              placeholder="Re-type your password"
              textSize="base"
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={validateForm}
              error={errors.confirmPassword}
              touched={!!errors.confirmPassword}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default PasswordModal;
