import apiService from "./api";

const getAllTask = async (params: {
  page: number;
  size: number;
  status?: string;
  assignedTo?: string;
  title?: string;
  taskDescription?: string;
}) => {
  const { page, size, status, assignedTo, title, taskDescription } = params;

  let url = `/tasks?page=${page}&size=${size}&sort=createdDate%2Cdesc`;

  if (status) {
    url += `&status=${status}`;
  }

  if (assignedTo) {
    url += `&assignedTo=${assignedTo}`;
  }

  if (title) {
    url += `&title=${title}`;
  }
  if (taskDescription) {
    url += `&title=${taskDescription}`;
  }

  const method = "get";
  return apiService(url, method, null, true);
};

const addNewTask = async (body: any) => {
  const url = "/tasks";
  const method = "post";
  return apiService(url, method, body, true);
};

const getTaskById = async (publicId: any) => {
  const url = `/tasks/${publicId}`;
  const method = "get";

  return apiService(url, method, null, true);
};

const editTask = async (publicId: any, body: any) => {
  const url = `/tasks/${publicId}`;
  const method = "put";
  // const requestData = body;
  return apiService(url, method, body, true);
};

const updateStatus = async (publicId: string, tenantTaskStatus: string) => {
  const url = `/tasks/status?publicId=${publicId}&taskStatus=${tenantTaskStatus}`;
  const method = "put";
  return apiService(url, method, null, true);
};

const deleteTask = async (publicId: any, token: any) => {
  const url = `/tasks/${publicId}`;
  const method = "post";
  // const requestData = body;

  const headers = {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: `Bearer ${token}`,
  };
  return apiService(url, method, null, true, headers);
};

const commentByTaskId = async (taskId: string, body: any) => {
  const url = `/tasks/${taskId}/create-comment`;
  const method = "post";

  return apiService(url, method, body, true);
};

const getCommentByTaskId = async (taskId: string) => {
  const url = `/tasks/${taskId}/comments`;
  const method = "get";

  return apiService(url, method, null, true);
};

const replyByCommentId = async (
  taskId: string,
  commentId: string,
  body: any
) => {
  const url = `/tasks/${taskId}/${commentId}/create-reply`;
  const method = "post";

  return apiService(url, method, body, true);
};

const getReplyByCommentId = async (taskId: string, commentId: string) => {
  const url = `/tasks/${taskId}/${commentId}/replies`;
  const method = "get";

  return apiService(url, method, null, true);
};

export {
  getAllTask,
  addNewTask,
  editTask,
  deleteTask,
  getTaskById,
  commentByTaskId,
  getCommentByTaskId,
  getReplyByCommentId,
  replyByCommentId,
  updateStatus,
};
