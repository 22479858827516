import { useSidebarContext } from "context/sidebar";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ExtraSidebar = ({ extraSidebarPaths }: any) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const {
    updateSidebarState,
    setPathName,
    extraSidebarPaths: frameworkPaths,
    updatePaths,
    newPath,
  } = useSidebarContext();
  const [isActive, setIsActive] = useState("All Requirement");

  const handleSetActive = (path: string) => {
    setIsActive(path);
  };

  const handleActivatePath = (path: string) => {
    updateSidebarState("COMPLIANCE", "Frameworks", true);
    updatePaths(path);
  };

  return (
    <div className="w-[15%] min-w-[250px] h-full overflow-y-scroll bg-white border-r border-[#D7DFE9] py-[20px] px-[8px] flex flex-col gap-[8px]">
      <h1 className="text-[#455468] font-bold text-xs leading-[20px] w-full">
        {extraSidebarPaths?.some((sidePath: any) => sidePath.children)
          ? "REQUIREMENTS"
          : ""}
      </h1>

      {currentPath.includes("/frameworks") && (
        <p
          className={`w-full px-[12px] hover:bg-[#E5EAFC] hover:text-[#1B4DFF] rounded-[6px] ${
            isActive === "All Requirement"
              ? "bg-[#E5EAFC] text-[#1B4DFF]"
              : "text-[#455468] bg-transparent"
          }`}
          onClick={() => {
            handleSetActive("All Requirement");
            setPathName("All Requirement");
          }}
        >
          <button className="text-left py-1 text-xs">All Requirement</button>
        </p>
      )}

      <ul className="w-full flex flex-col gap-3">
        {currentPath.includes("/frameworks")
          ? frameworkPaths?.map((sidePath: any, index: any) => {
              return (
                <ul key={index} className="w-full flex flex-col gap-3">
                  {sidePath.children ? (
                    sidePath.group === "ungrouped" ? (
                      sidePath.children.map((pikin: any, j: any) => (
                        <li
                          onClick={() => {
                            handleSetActive(pikin.label);
                            setPathName(pikin.label);
                          }}
                          className={`${
                            isActive === pikin.label
                              ? "bg-[#E5EAFC] text-[#1B4DFF]"
                              : "text-[#455468] bg-transparent"
                          } w-full px-[12px] hover:bg-[#E5EAFC] hover:text-[#1B4DFF] text-left py-1 text-xs rounded-md cursor-pointer`}
                          key={j}
                        >
                          {pikin.label}
                        </li>
                      ))
                    ) : (
                      <div key={index}>
                        <h2 className="text-[#455468] font-bold text-xs leading-[20px] w-full py-3">
                          {sidePath.group}
                        </h2>
                        <ul className="flex flex-col gap-3">
                          {sidePath.children.map((child: any, k: any) => (
                            <li
                              key={k}
                              className="text-sm font-medium px-1 flex gap-1 items-center rounded-md hover:bg-[#E5EAFC] hover:text-[#1B4DFF] cursor-pointer"
                              onClick={() => {
                                handleSetActive(child.label);
                                setPathName(child.label);
                              }}
                            >
                              <button
                                className={`${
                                  isActive === child.label
                                    ? "bg-[#E5EAFC] text-[#1B4DFF]"
                                    : "text-[#455468] bg-transparent"
                                } px-[5px] rounded-[6px] hover:bg-[#E5EAFC] hover:text-[#1B4DFF] text-left text-xs`}
                              >
                                {child.sectionNo} {child.label}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )
                  ) : (
                    <li
                      key={sidePath.path}
                      className={`w-full text-sm px-3 rounded-md hover:bg-[#E5EAFC] ${
                        isActive === sidePath.path
                          ? "bg-[#E5EAFC] text-[#1B4DFF]"
                          : "text-[#455468]"
                      }`}
                      onClick={() => {
                        updateSidebarState("COMPLIANCE", "Frameworks", false);
                        handleSetActive(sidePath.path);
                      }}
                    >
                      <button>{sidePath.label}</button>
                    </li>
                  )}
                </ul>
              );
            })
          : extraSidebarPaths?.map((sidePath: any, index: any) => (
              <React.Fragment key={index}>
                <li
                  key={sidePath.path}
                  className={`w-full text-sm px-3 rounded-md hover:bg-[#E5EAFC] ${
                    newPath === sidePath.path
                      ? "bg-[#E5EAFC] text-[#1B4DFF]"
                      : "text-[#455468]"
                  }`}
                  onClick={() => {
                    // updateSidebarState("COMPLIANCE", "Frameworks", true);
                    // navigate(sidePath.path);
                    handleActivatePath(sidePath.path);
                  }}
                >
                  <button>{sidePath.label}</button>
                </li>
              </React.Fragment>
            ))}
      </ul>
    </div>
  );
};

export default ExtraSidebar;
