import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import CustomIcon from "components/custom-icon";
import { ReactComponent as Workspace } from "assets/images/workspace.svg";
import { ReactComponent as IconExist } from "assets/icons/Iconexist.svg";
import { onboardingProcess } from "constants/onboarding";
import OnboardingProgress from "../OnboardingProgress";
import useOnboardingHelpers from "helpers/onboaarding";

const OnboardingSidebar = () => {
  const { updateNavigationHistory } = useOnboardingHelpers();
  const { pathname } = useLocation();
  const [activePaths, setActivePaths] = useState([pathname]);
  const historyStack = useRef([pathname]);

  useEffect(() => {
    updateNavigationHistory(pathname, historyStack, setActivePaths);
  }, [pathname]);

  const sendEmail = () => {
    const recipientEmail = "admin@vegeel.com";
    const subject = "";
    const body = "";
    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };
  return (
    <div className="h-fit w-[305px] py-[16px] px-[10px] border border-[#D7DFE9] rounded-md flex flex-col gap-2">
      <OnboardingProgress />
      <div className="w-[280px] h-fit py-2 flex flex-col gap-2">
        {onboardingProcess.map((data) => (
          <div
            key={data.title}
            className={`px-1 w-full rounded-md flex gap-3 items-center ${
              data.pathname === pathname ? "bg-[#E5EAFC]" : ""
            }`}
          >
            <CustomIcon
              SvgIcon={
                activePaths.includes(data.pathname)
                  ? data.activeIcon
                  : data.icon
              }
              size={12}
            />
            <p
              className={`${
                activePaths.includes(data.pathname)
                  ? "text-main_color"
                  : "text-[#455468]"
              } text-sm font-medium leading-[24px] `}
            >
              {data.title}
            </p>
          </div>
        ))}
      </div>
      <div className="w-[213px] h-fit py-1 flex flex-col gap-2">
        <CustomIcon SvgIcon={Workspace} size={60} />
        <p className="text-sm text-[#111111] font-medium  leading-[24px]">
          Having issues?
        </p>
        <p className="text-[#455468] font-normal text-xs leading-[20px] ">
          Feel free to contact us. We will be glad to help you through the
          process.
        </p>
        <button className="w-[111px] py-[6px] flex justify-center items-center gap-[10px] border border-[#D7DFE9]">
          <span
            className="text-xs font-medium  text-[#455468] leading-[20px]"
            onClick={sendEmail}
          >
            Contact us
          </span>
          <CustomIcon SvgIcon={IconExist} size={10} />
        </button>
      </div>
    </div>
  );
};

export default OnboardingSidebar;
