import React, { InputHTMLAttributes } from "react";
import { ReactComponent as PrevIcon } from "assets/icons/prev-icon.svg";
import { ReactComponent as NextIcon } from "assets/icons/next-icon.svg";
import CustomIcon from "components/custom-icon";

interface ButtonProps extends InputHTMLAttributes<HTMLButtonElement> {
  label: "next" | "back" | "skip" | "Done" | "Create Audit";
  icon?: boolean;
  variant: "contained" | "outline" | "plain" | "create";
  onClick?: () => void;
}

export default function OnboardingButton({
  icon,
  label,
  variant,
  disabled,
  onClick,
}: ButtonProps) {
  const getButtonVariant = () => {
    switch (variant) {
      case "contained":
        return "bg-[#1B4DFF] text-white";
      case "outline":
        return "border border-[#D7DFE9] text-[#455468]";
      case "plain":
        return "";
      case "create":
        return "px-2 w-fit bg-[#1B4DFF] text-white whitespace-nowrap ";

      default:
        return "";
    }
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`w-[88px] h-[40px] rounded-md flex items-center justify-center gap-[10px] text-sm leading-[24px] capitalize font-medium whitespace-nowrap ${getButtonVariant()} ${
        disabled ? "cursor-not-allowed" : ""
      }`}
    >
      {icon && label === "back" && <CustomIcon SvgIcon={PrevIcon} size={15} />}
      <span>{label}</span>
      {icon && label === "Create Audit" && (
        <CustomIcon SvgIcon={NextIcon} size={15} />
      )}
      {icon && label === "next" && <CustomIcon SvgIcon={NextIcon} size={15} />}
      {icon && label === "Done" && <CustomIcon SvgIcon={NextIcon} size={15} />}
    </button>
  );
}
