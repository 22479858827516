import apiService from "./api";

const adminInfo = async (body: any) => {
  return apiService("/admin/", "get", body);
};

const getvegeelbusinessservices = async () => {
  return apiService("/vegeelbusinessservices/", "get", null, true);
};

const fetchFrameworkStats = async (name: string) => {
  return apiService(
    `/frameworks/framework-stats/?name=${encodeURIComponent(name)}`,
    "get",
    null,
    true
  );
};

const fetchDocumentStats = async (publicId: string) => {
  return apiService(
    `/evidencehub/dashboard?vegeelFrameworkPublicId=${publicId}`,
    "get",
    null,
    true
  );
};

const fetchDocumentExpiration = async () => {
  return apiService(
    "/evidencehub/document-expiration-stats",
    "get",
    null,
    true
  );
};

const fetchFrameworkRequirements = async (publicId: string) => {
  return apiService(
    `/vegeel/frameworks/requirements/count/${publicId}`,
    "get",
    null,
    true
  );
};
const getNotificationsNotRead = async () => {
  return apiService(`/notification?page=0&size=20`, "get", null, true);
};

export {
  adminInfo,
  getNotificationsNotRead,
  getvegeelbusinessservices,
  fetchFrameworkStats,
  fetchDocumentStats,
  fetchDocumentExpiration,
  fetchFrameworkRequirements,
};
