import axios from "axios";

export const handleError = (error: any): string => {
  let errorMessage = "An error occurred";

  if (axios.isCancel(error)) {
    errorMessage = "Request canceled";
  } else if (error.code === "ECONNABORTED") {
    errorMessage = "Request timed out";
  } else if (!error.response) {
    errorMessage = "Network error";
  } else {
    const responseData = error.response.data as {
      type?: string;
      title?: string;
      status?: number;
      detail?: string;
      instance?: string;
      description?: string;
      timestamp?: string;
      isError?: boolean;
      isSuccess?: boolean;
      error?: string;
      error_description?: string;
      message?: string;
    };

    if (responseData) {
      // Handle Keycloak error structure
      if (responseData.error && responseData.error_description) {
        errorMessage = responseData.error_description;
      } else if (responseData.detail || responseData.description) {
        errorMessage =
          responseData.detail || responseData.description || errorMessage;
      } else {
        switch (error.response.status) {
          case 400:
            errorMessage =
              "Bad Request: The server could not understand the request due to invalid syntax.";
            break;
          case 401:
            errorMessage =
              "Unauthorized: Access is denied due to invalid credentials.";
            break;
          case 403:
            errorMessage =
              "Forbidden: You don't have permission to access this resource.";
            break;
          case 404:
            errorMessage =
              "Not Found: The requested resource could not be found.";
            break;
          case 500:
            errorMessage =
              "Internal Server Error: The server has encountered a situation it doesn't know how to handle.";
            break;
          case 503:
            errorMessage =
              "Service Unavailable: The server is not ready to handle the request.";
            break;
          default:
            errorMessage = responseData.message || errorMessage;
        }
      }
    }
  }

  return errorMessage;
};
