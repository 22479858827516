import { useAppContext } from "context/home/app";
import useFetch from "hooks/useFetch";
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";
import {
  getPersonel,
  getRoles,
  getSettingsNotification,
  updatePersonel,
  updateSettingsNotification,
} from "services/personnel";
import { toast } from "sonner";
import { findRolePublicId } from "utils/general";

interface Role {
  publicId: string;
  name: string;
  permissions: any[];
}
interface IDataResponse {
  isSuccess: boolean;
  isError: boolean;
  description: string;
  status: string;
  data: IDataContent;
  timestamp: string;
}
interface IDataContent {
  content: Role[];
}
interface SettingsContextProps {
  rolesData: Role[];
  setRolesData: (data: Role[]) => void;
  loading: boolean;
  error: any;
  data: IDataResponse | null;
  setReload: (value: boolean) => void;
  notificationsSettingData: any;
  setNotificationsSettingData: any;
  notificationBody: any;
  setNotificationBody: any;
  securityRequestBody: any;
  setSecurityRequestBody: any;
  notificatioinLoading: boolean;
  noficationError: any;
  setMultifactiorAuth: any;
  multifactorAuth: any;
  personelData: any;
  setPersonelData: any;
  seveChanges: boolean;
  setSaveChanges: any;
  setData2Fa: any;
  data2fa: boolean | null;
}
interface SettingsProviderProps {
  children: ReactNode;
}

const SettingsContext = createContext<SettingsContextProps | undefined>(
  undefined
);

export function SettingsProvider({ children }: SettingsProviderProps) {
  const { pathname } = useLocation();

  const {
    error: noficationError,
    loading: notificatioinLoading,
    fetchData: fetchNotification,
    data: notificationData,
  } = useFetch<IDataResponse>();

  const {
    error: getpersonnelError,
    loading: personnelLoading,
    fetchData: fetchPerosnnel,
    data: personeResponseData,
  } = useFetch<IDataResponse>();

  const {
    fetchData: updateSettingsNftcn,
    error: upDateNftError,
    loading: upDateNftLoading,
    data: upDatedNftData,
  } = useFetch<IDataResponse>();

  const {
    error: uppdatePersonelError,
    loading: updatePersonelLoading,
    fetchData: updateAPersonel,
    data: updatedPersonelData,
  } = useFetch<any>();

  const { error, loading, fetchData, data } = useFetch<IDataResponse>();
  const [seveChanges, setSaveChanges] = useState<boolean>(false);
  const [rolesData, setRolesData] = useState<Role[]>([]);
  const [personelData, setPersonelData] = useState<any | null>({});
  const [notificationBody, setNotificationBody] = useState<any>({});
  const [securityRequestBody, setSecurityRequestBody] = useState<any>({});
  const [reload, setReload] = useState<boolean>(false);
  const [multifactorAuth, setMultifactiorAuth] = useState<boolean | null>();
  const { getUserInfo, setUserInfo } = useAppContext();
  const [notificationsSettingData, setNotificationsSettingData] = useState<any>(
    {}
  );
  const [data2fa, setData2Fa] = useState<boolean>(false);
  const userInfo = getUserInfo();
  const id = userInfo?.publicId;

  useEffect(() => {
    if (seveChanges) {
      if (pathname === "/company-settings/security") {
        update();
      }
      setSaveChanges(false);
    }
  }, [seveChanges, pathname]);

  useEffect(() => {
    fetchData(() => getRoles());
    fetchNotification(() => getSettingsNotification(id));
    fetchPerosnnel(() => getPersonel(id));
  }, []);

  useEffect(() => {
    if (reload) {
      fetchData(() => getRoles());
      fetchNotification(() => getSettingsNotification(id));
      fetchPerosnnel(() => getPersonel(id));
      setReload(false);
    }
  }, [reload, fetchData]);

  useEffect(() => {
    if (data?.isSuccess) {
      setRolesData(data.data.content);
    }
  }, [data]);

  useEffect(() => {
    if (personeResponseData?.isSuccess) {
      setPersonelData(personeResponseData.data);
    }
  }, [personeResponseData]);

  useEffect(() => {
    if (notificationData?.isSuccess) {
      setNotificationsSettingData(notificationData.data);
    }
  }, [notificationData]);

  const matchingRoleId = findRolePublicId(rolesData, personelData);

  const update = () => {
    if (
      securityRequestBody.autoLogOut !== null &&
      securityRequestBody.idleSessiontimeOut !== null
    ) {
      const body = {
        username: personelData.username,
        firstName: personelData.firstName,
        lastName: personelData.lastName,
        rolePublicId: matchingRoleId,
        email: personelData.email,
        department: personelData.department,
        position: personelData.position,
        partner: personelData.partner,
        automaticLogoutTime: securityRequestBody.autoLogOut,
        sessionTimeout: securityRequestBody.idleSessiontimeOut,
      };

      updateAPersonel(() => updatePersonel(id, body));
    }
  };

  useEffect(() => {
    if (updatedPersonelData) {
      toast.success("Updated Successfully");
      setUserInfo(updatedPersonelData.data);
    }
  }, [updatedPersonelData]);

  return (
    <SettingsContext.Provider
      value={{
        notificationBody,
        setNotificationBody,
        notificationsSettingData,
        setNotificationsSettingData,
        setReload,
        rolesData,
        setRolesData,
        loading,
        error,
        data,
        securityRequestBody,
        setSecurityRequestBody,
        notificatioinLoading,
        noficationError,
        multifactorAuth,
        setMultifactiorAuth,
        personelData,
        setPersonelData,
        seveChanges,
        setSaveChanges,
        data2fa,
        setData2Fa,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
}
