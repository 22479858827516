import iconLoader from "assets/icons/Icon-loader.svg";
import IconClose from "assets/icons/IconClose.svg";

const OnboardingProgress = () => {
  return (
    <div className="h-fit w-[280px] rounded-md border border-[#EAECF0]">
      <div className="px-[12px] py-[10px] flex justify-between border-b border-[#EAECF0]">
        <div className="flex gap-4">
          <img src={iconLoader} alt="Loader" className="rotate-infinite" />
          <h1 className="text-[#111111] text-sm leading-[20px] ">
            Onboarding progress
          </h1>
        </div>
        <button>
          <img src={IconClose} alt="Close" />
        </button>
      </div>
      <div className="flex flex-col px-[12px] py-[10px]">
        <h1 className="text-[#111111] text-xs font-medium  leading-[20px]">
          Company details
        </h1>
        <p className="text-[#455468] text-xs font-medium  leading-[20px]">
          Complete all steps to begin compliance management
        </p>
      </div>
    </div>
  );
};

export default OnboardingProgress;
