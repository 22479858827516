import { IUserDataDetails } from "context/home/app";
import React, {
  ReactNode,
  createContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { getTokenFromLocalStore, saveKeyToLocalStore } from "utils";

interface OnboardingCompanyInfo {
  companylegalname: string;
  companydisplayname: string;
  companysector: string;
  companyentitytype: string;
  companyregnumber: string;
  companyoverview: string;
  companyprofileimage: File | null;
  hasCompleted: boolean;
}

interface OnboardingContactDetails {
  companyphonenumber: string;
  companyemail: string;
  companywebsite: string;
  hasCompleted: boolean;
}

interface OnboardingAddress {
  companyaddressone: string;
  companyaddresstwo: string;
  companycity: string;
  companystate: string;
  companyzipcode: string;
  companycountry: string;
  hasCompleted: boolean;
}

interface OnboardingUrls {
  companytermofuseurl: string | null | undefined;
  companyprivacypolicyurl: string | null | undefined;
  companyserviceagreementurl: string | null | undefined;
  companyfaqurl: string | null | undefined;
  hasCompleted: boolean;
}

export interface OnboardingContextType {
  companyInfo: OnboardingCompanyInfo;
  contactDetails: OnboardingContactDetails;
  address: OnboardingAddress;
  urls: OnboardingUrls;
  aggreement: boolean;
  setOnboardingForm: Dispatch<SetStateAction<OnboardingContextType>>;
}

const initialOnboardingState: OnboardingContextType = {
  companyInfo: {
    companylegalname: "",
    companydisplayname: "",
    companysector: "",
    companyentitytype: "",
    companyregnumber: "",
    companyoverview: "",
    companyprofileimage: null,
    hasCompleted: false,
  },
  contactDetails: {
    companyphonenumber: "",
    companyemail: "",
    companywebsite: "",
    hasCompleted: false,
  },
  address: {
    companyaddressone: "",
    companyaddresstwo: "",
    companycity: "",
    companystate: "",
    companyzipcode: "",
    companycountry: "",
    hasCompleted: false,
  },
  urls: {
    companytermofuseurl: "",
    companyprivacypolicyurl: "",
    companyserviceagreementurl: "",
    companyfaqurl: "",
    hasCompleted: true,
  },
  aggreement: false,
  setOnboardingForm: () => {},
};

export const OnboardingContext = createContext<
  OnboardingContextType | undefined
>(undefined);

export const OnboardingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [onboardingForm, setOnboardingForm] = useState<OnboardingContextType>(
    () => {
      const savedState = getTokenFromLocalStore<string>("onboardingForm");
      if (savedState) {
        try {
          const data: OnboardingContextType = JSON.parse(savedState);
          return data || initialOnboardingState;
        } catch (error) {
          console.error("Error parsing saved onboarding state:", error);
        }
      }
      return initialOnboardingState;
    }
  );

  useEffect(() => {
    saveKeyToLocalStore("onboardingForm", JSON.stringify(onboardingForm));
  }, [onboardingForm]);

  useEffect(() => {
    const validateCompletion = () => {
      const isCompanyInfoComplete =
        onboardingForm.companyInfo &&
        Object.entries(onboardingForm.companyInfo).every(
          ([key, value]) =>
            key === "companylegalname" || (value !== "" && value !== null)
        );

      const isContactDetailsComplete =
        onboardingForm.contactDetails &&
        Object.values(onboardingForm.contactDetails).every(
          (value) => value !== ""
        );

      const isAddressComplete =
        onboardingForm.address &&
        Object.values(onboardingForm.address).every((value) => value !== "");

      const isUrlsComplete =
        onboardingForm.urls &&
        Object.values(onboardingForm.urls).every((value) => value !== "");

      setOnboardingForm((prev) => {
        const updatedForm = {
          ...prev,
          companyInfo: {
            ...prev.companyInfo,
            hasCompleted: isCompanyInfoComplete,
          },
          contactDetails: {
            ...prev.contactDetails,
            hasCompleted: isContactDetailsComplete,
          },
          address: {
            ...prev.address,
            hasCompleted: isAddressComplete,
          },
          urls: {
            ...prev.urls,
            hasCompleted: isUrlsComplete,
          },
        };

        if (JSON.stringify(prev) === JSON.stringify(updatedForm)) {
          return prev;
        }

        return updatedForm;
      });
    };

    validateCompletion();
  }, [onboardingForm]);

  return (
    <OnboardingContext.Provider
      value={{ ...onboardingForm, setOnboardingForm }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
