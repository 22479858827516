import React, { useEffect, useState } from "react";
import TimelineComponent from "./Timeline";
import { INonApplicableItem, IReqItem } from "types/framework";
import useFetch from "hooks/useFetch";
import { getReqHistory } from "services";
import { toast } from "sonner";
import { IPersonnel } from "types/personnel";
import Loader from "components/loader";
import { Timeline } from "antd";
import CustomIcon from "components/custom-icon";
import { ReactComponent as Clock } from "assets/icons/clock.svg";
import { formatDate } from "utils/formatDate";
import { IEvidenceHubItem } from "types/evidence-hub";

interface IHistoryTabItem {
  reqItem?: IReqItem | INonApplicableItem | IEvidenceHubItem;
}

interface IHistoryResponse {
  timestamp: string;
  data: {
    content: IHistoryItem[];
    page: {
      size: number;
      number: number;
      totalElements: number;
      totalPages: number;
    };
  };
  isSuccess: boolean;
  isError: boolean;
  description: string;
  status: string;
}

export interface IHistoryItem {
  id: number;
  publicId: string;
  createdDate: string;
  lastModifiedDate: string;
  createdByRole: string;
  createdBy: string;
  lastModifiedBy: string;
  description: string;
  action: string;
  personnel: IPersonnel;
  entity: string;
  entityPublicId: string;
}

export default function HistoryTab({ reqItem }: IHistoryTabItem) {
  const [history, setHistory] = useState<IHistoryItem[]>([]);

  const { data, error, fetchData, loading } = useFetch<IHistoryResponse>();
  useEffect(() => {
    if (reqItem) {
      fetchData(() => getReqHistory(reqItem.publicId));
    }
  }, [fetchData, reqItem]);

  useEffect(() => {
    if (data) {
      setHistory(data.data.content);
    }

    if (error) {
      toast.error(error);
    }
  }, [data, error, reqItem]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="py-[10px] px-[24px] min-h-[200px]">
      {history.map((item) => {
        return <TimelineComponent history={item} key={item.publicId} />;
      })}
    </div>
  );
}
