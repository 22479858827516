import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_SECRET_KEY as string;

if (!secretKey) {
  throw new Error(
    "Secret key is not defined. Please set REACT_APP_SECRET_KEY in your environment variables."
  );
}

const checkTokenExpiry = (tokenStr?: string): boolean => {
  let expireTime: number = 0;
  if (expireTime === 0) {
    try {
      const token = tokenStr || getTokenFromLocalStore("access_token");
      if (token) {
        const decodedToken = jwtDecode<{ exp: number }>(token);
        expireTime = decodedToken.exp;
      }
    } catch (error) {
      console.error("Token decoding failed:", error);
      return true;
    }
  }
  return Date.now() >= expireTime * 1000;
};

export const encryptData = (data: any, secretKey: string): string => {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  return ciphertext;
};

export const decryptData = (ciphertext: string, secretKey: string): any => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

const getTokenFromLocalStore = <T>(key: string): T | null => {
  const encryptedData = sessionStorage.getItem(key);
  if (encryptedData) {
    return decryptData(encryptedData, secretKey) as T;
  }
  return null;
};

const saveTokenToLocalStore = (key: string, value: any) => {
  const encryptedData = encryptData(value, secretKey);
  sessionStorage.setItem(key, encryptedData);
};

const saveKeyToLocalStore = (key: string, value: string) => {
  const encryptedData = encryptData(value, secretKey);
  sessionStorage.setItem(key, encryptedData);
};

const clearAll = (changeWindow: boolean = true) => {
  sessionStorage.clear();
  if (changeWindow && typeof window !== "undefined") {
    window.location.href = "/auth";
  }
};

const clearKey = (specificKey: string) => {
  sessionStorage.removeItem(specificKey);
};

export {
  getTokenFromLocalStore,
  saveTokenToLocalStore,
  checkTokenExpiry,
  clearAll,
  clearKey,
  saveKeyToLocalStore,
};
