// @ts-nocheck
import React from "react";
import CustomIcon from "components/custom-icon";
import { ReactComponent as file } from "assets/icons/file-05.svg";
import { useAppContext } from "context";
import { getPersonnelProfile } from "utils/general";
import { formatDate } from "utils/formatDate";

interface NotifyItemsProps {
  avatar: string;
  title: string;
  audit: any;
  date?: string;
  attachments?: any;
}

const NotifyItems: React.FC<NotifyItemsProps> = ({
  avatar,
  title,
  audit,
  date,
  attachments,
}) => {
  const { personnelInfoState } = useAppContext();

  return (
    <div className="w-full">
      <div className="flex gap-3">
        <div>
          <div className="w-[32px] aspect-square rounded-full overflow-hidden items-center justify-center">
            <img
              className="w-full h-full object-cover"
              src={getPersonnelProfile(personnelInfoState.content, avatar)}
              alt=""
            />
          </div>
        </div>
        <div className="w-full">
          <div>
            <div className="w-full flex items-center justify-between gap-16">
              <p className="text-sm font-medium text-[#455468]">{title}</p>
              <div className="w-2 bg-[#027A48] aspect-square rounded-full"></div>
            </div>
            <div className="w-full flex items-center justify-between mt-1">
              <p className="text-xs text-[#667085]">
                today
                {/* <span className="underline text-[#111111]">{audit}</span>*/}
              </p>
              <p className="text-xs text-[#667085]">
                {formatDate(date || "")}{" "}
              </p>
            </div>
            <div className="flex gap-2 bg-[#FAFAFA] p-2 rounded-md w-fit mt-4">
              {attachments.map((data: any, index: number) => (
                <div className="" key={index}>
                  <div
                    className={`${
                      data.endsWith(".png" || ".jpg") ? "hidden" : ""
                    }`}
                  >
                    <div className="w-[32px] aspect-square flex rounded-md items-center justify-center bg-[#E5EAFC]">
                      <CustomIcon SvgIcon={file} size={16} />
                    </div>
                  </div>
                  {data.endsWith(".pdf" || ".doc") ? (
                    <div className="w-full">
                      <div className="flex items-center gap-1">
                        <p className="text-xs text-[#455468] font-bold">file</p>
                        <div className="w-0.5 aspect-square rounded-full bg-[#667085]"></div>
                        <p className="text-xs text-[#667085]">0.19MB</p>
                      </div>
                      <div className="flex items-center gap-1">
                        <p className="text-xs text-[#667085]">.doc</p>
                        <div className="w-0.5 aspect-square rounded-full bg-[#667085]"></div>
                        {/* <p className='text-xs text-[#667085]'>{pdf.date}</p> */}
                      </div>
                    </div>
                  ) : (
                    <img
                      src={data}
                      alt="sent_image"
                      className="h-[80px] w-auto "
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotifyItems;
