import PreviewEvidenceHubEditor from "components/editor/preview-editor";
import React from "react";
import { useParams } from "react-router-dom";
import PreviewPolicyHubEditor from "./policy";
import CustomisedEditor from "components/editor/customised-template";

export default function EditorContainer() {
  const { page, framework, name } = useParams();

  const renderEditor = () => {
    switch (page) {
      case "evidence-hub":
        return <PreviewEvidenceHubEditor framworkname={name} />;
      case "customised-template":
        return <CustomisedEditor name={name} />;
      case "policy-center":
        return <PreviewPolicyHubEditor name={name} />;

      default:
        return <h1>404 page not found</h1>;
    }
  };

  return renderEditor();
}
