import apiService from "./api";

const getAllPolicy = async (params: {
  vegeelFrameworkPublicId: string;
  page: number;
  size: number;
  search?: any;
}) => {
  const { vegeelFrameworkPublicId, page, size, search } = params;
  let url = `/policy-center?vegeelFrameworkPublicId=${vegeelFrameworkPublicId}&page=${page}&size=${size}`;
  const method = "get";

  if (search) {
    url += `&documentName=${search}`;
  }

  return apiService(url, method, null, true);
};

const getPolicyByDocumentName = async (documentName: string) => {
  const url = `/policy-center?documentName=${documentName}`;
  const method = "get";
  return apiService(url, method, null, true);
};

export { getAllPolicy, getPolicyByDocumentName };
