import { IOrgInfoFramework, useAppContext } from "context";
import { useAuth } from "context/auth";
import useFetch from "hooks/useFetch";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getallframeworks,
  getFrameworkByName,
  getFrameworksByIds,
  getFrameworkStats,
  getFramweworkReqFromTenant,
  getNonApplicableReq,
} from "services";
import { toast } from "sonner";
import {
  IActiveFramework,
  IFrameworkResponse,
  INonApplicableItem,
  IReqItem,
  ISubscribedFrameworkResponse,
} from "types/framework";

interface FrameworkContextProps {
  activeFrameworks: IOrgInfoFramework[] | null;
  availableFrameworks: any[] | null;
  notApplicableFromTenant: INonApplicableItem[] | null;
  frameworkFromTenant: IReqItem[] | null;
  frameworkFromTenantData: any | null;
  frameworkByNameLoading: boolean;
  getStatLoading: boolean;
  frameworkFromTenantLoading: boolean;
  frameworkItem: IActiveFramework | null;
  allFrameworksLoading: boolean;
  getStatError: any;
  frameworkPaths: any;
  statusCounts: any;
  fetchFrameworkByName: (frameworkName: string) => Promise<void>;
  setActiveFrameworks: React.Dispatch<
    React.SetStateAction<IOrgInfoFramework[]>
  >;
  setFrameworkPaths: React.Dispatch<any>;
  setFrameworkFromTenant: React.Dispatch<
    React.SetStateAction<IReqItem[] | null>
  >;
  fetchFrameworkFromTenant: (frameworkPublicId: string) => Promise<void>;
  fetchNotAppFromTenant: (frameworkPublicId: string) => Promise<void>;
  fetchFrameworkStats: (name: string) => Promise<void>;
  setFrameworkItem: React.Dispatch<
    React.SetStateAction<IActiveFramework | null>
  >;
}

const FrameworkContext = createContext<FrameworkContextProps | undefined>(
  undefined
);

export const FrameworkProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeFrameworks, setActiveFrameworks] = useState<IOrgInfoFramework[]>(
    []
  );
  const [availableFrameworks, setAvailableFrameworks] = useState<
    IActiveFramework[] | null
  >(null);
  const [frameworkFromTenant, setFrameworkFromTenant] = useState<
    IReqItem[] | null
  >(null);
  const [notApplicableFromTenant, setNotApplicableFromtenant] = useState<any>();
  const [frameworkPaths, setFrameworkPaths] = useState<any>([]);
  const [frameworkItem, setFrameworkItem] = useState<IActiveFramework | null>(
    null
  );
  const [statusCounts, setStatusCounts] = useState<any | null>(0);

  const { getOrganizationInfo } = useAppContext();

  const {
    data: frameworkByNameData,
    error: frameworkByNameError,
    loading: frameworkByNameLoading,
    fetchData: frameworkByNameFetch,
  } = useFetch<ISubscribedFrameworkResponse>();
  const {
    data: frameworkFromTenantData,
    error: frameworkFromTenantError,
    loading: frameworkFromTenantLoading,
    fetchData: frameworkFromTenantFetch,
  } = useFetch<IFrameworkResponse>();
  const {
    data: allFrameworks,
    loading: allFrameworksLoading,
    error: allFrameworksError,
    fetchData: allFrameworksFetch,
  } = useFetch<any>();
  const {
    data: nonApplicableData,
    error: nonApplicableError,
    fetchData: nonApplicableFetchData,
    loading: nonApplicableLoading,
  } = useFetch<any>();

  const {
    data: getStatData,
    error: getStatError,
    loading: getStatLoading,
    fetchData: getStatFetch,
  } = useFetch<any>();

  useEffect(() => {
    if (getStatData) {
      setStatusCounts(getStatData.data);
    }
  }, [getStatData]);

  useEffect(() => {
    if (frameworkItem) {
      const frameworkSections = frameworkItem.frameworkSections.reduce(
        (acc: any, section: any) => {
          const { requirementGroup } = section;

          if (requirementGroup) {
            if (!acc[requirementGroup]) {
              acc[requirementGroup] = {
                group: requirementGroup,
                children: [],
              };
            }
            acc[requirementGroup].children.push({
              label: section.sectionTitle,
              sectionNo: section.sectionNo,
            });
          } else {
            if (!acc["ungrouped"]) {
              acc["ungrouped"] = {
                group: "ungrouped",
                children: [],
              };
            }
            acc["ungrouped"].children.push({
              label: section.sectionTitle,
              sectionNo: section.sectionNo,
            });
          }
          return acc;
        },
        {}
      );

      const sidebarPaths = {
        label: frameworkItem.name,
        path: `/frameworks/${frameworkItem.name}`,
        sections: Object.values(frameworkSections),
      };

      setFrameworkPaths(sidebarPaths.sections);
    }
  }, [frameworkItem]);

  const orgInfo = getOrganizationInfo();

  useEffect(() => {
    allFrameworksFetch(() => getallframeworks());
  }, []);

  useEffect(() => {
    if (
      allFrameworksError ||
      frameworkFromTenantError ||
      frameworkByNameError
    ) {
      toast.error(
        allFrameworksError || frameworkFromTenantError || frameworkByNameError
      );
    }
  }, [allFrameworksError, frameworkByNameError, frameworkFromTenantError]);

  useEffect(() => {
    if (allFrameworks) {
      const frameworkWithName = allFrameworks.data.content.filter(
        (framework: any) => framework.name !== null
      );
      setAvailableFrameworks(frameworkWithName);
    }
  }, [allFrameworks]);

  useEffect(() => {
    if (frameworkByNameData) {
      setFrameworkItem(frameworkByNameData.data.content[0]);
    }
  }, [frameworkByNameData]);

  useEffect(() => {
    if (orgInfo) {
      setActiveFrameworks(orgInfo.businessFrameworks || []);
    }
  }, []);

  const fetchFrameworkByName = async (frameworkName: string) => {
    frameworkByNameFetch(() => getFrameworkByName(frameworkName));
  };

  const fetchFrameworkStats = async (name: string) => {
    getStatFetch(() => getFrameworkStats(name));
  };

  const fetchFrameworkFromTenant = async (frameworkPublicId: string) => {
    frameworkFromTenantFetch(() =>
      getFramweworkReqFromTenant(frameworkPublicId)
    );
  };

  const fetchNotAppFromTenant = async (frameworkPublicId: string) => {
    nonApplicableFetchData(() => getNonApplicableReq(frameworkPublicId));
  };

  useEffect(() => {
    if (frameworkFromTenantData) {
      setFrameworkFromTenant(frameworkFromTenantData.data);
    }
    if (nonApplicableData) {
      setNotApplicableFromtenant(nonApplicableData.data);
    }
  }, [frameworkFromTenantData, nonApplicableData]);

  return (
    <FrameworkContext.Provider
      value={{
        activeFrameworks,
        frameworkFromTenant,
        availableFrameworks,
        allFrameworksLoading,
        frameworkByNameLoading,
        frameworkItem,
        frameworkFromTenantData,
        frameworkPaths,
        frameworkFromTenantLoading,
        notApplicableFromTenant,
        statusCounts,
        getStatError,
        getStatLoading,
        fetchFrameworkByName,
        setActiveFrameworks,
        setFrameworkFromTenant,
        fetchFrameworkFromTenant,
        fetchNotAppFromTenant,
        fetchFrameworkStats,
        setFrameworkItem,
        setFrameworkPaths,
      }}
    >
      {children}
    </FrameworkContext.Provider>
  );
};

export const useFrameworkContext = () => {
  const context = useContext(FrameworkContext);
  if (!context) {
    throw new Error(
      "useFrameworkContext must be used within a FrameworkProvider"
    );
  }
  return context;
};
