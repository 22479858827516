import apiService from "./api";
const baseUrl = process.env.REACT_APP_SHARED_BASE_URL;

const getUserInfoService = async (personnelId: any) => {
  return apiService(
    `/personnel/${personnelId}`,
    "get",
    null,
    true,
    {},
    {},
    baseUrl
  );
};
const getOrgInfoService = async () => {
  return apiService("/onboarding/get-organization-info", "get", null, true);
};

const uploadFilesService = async (file: any, extension: string) => {
  const formData = new FormData();

  formData.append("file", file);

  const customHeaders = {
    "Content-Type": "multipart/form-data",
  };

  return apiService(
    "/utils/files/upload",
    "post",
    formData,
    true,
    customHeaders,
    undefined,
    baseUrl
  );
};

export { getOrgInfoService, getUserInfoService, uploadFilesService };
