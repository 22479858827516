import apiService from "./api";

const getAllCustomisedTemplate = async (param: {
  frameworkName: string;
  page: number;
  size: number;
  search?: any;
}) => {
  const { frameworkName, page, size, search } = param;
  let url = `/customized-template?vegeelFrameworkName=${frameworkName}&page=${page}&size=${size}`;
  const method = "get";
  if (search) {
    url += `&documentName=${search}`;
  }
  return apiService(url, method, null, true);
};

const getCustomisedTemplateByName = async (documentName: string) => {
  const url = `/customized-template?documentName=${documentName}`;
  const method = "get";
  return apiService(url, method, null, true);
};

export { getAllCustomisedTemplate, getCustomisedTemplateByName };
