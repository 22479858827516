import { IOrgDataResponse, useAppContext } from "context";
import { useAuth } from "context/auth";
import useFetch from "hooks/useFetch";
import { useOnboardingContext } from "hooks/useOnboarding";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getOrgInfoService,
  onboardingService,
  uploadFilesService,
} from "services";
import { toast } from "sonner";
import { getTokenFromLocalStore } from "utils";

export default function useOnboardingHelpers() {
  const [organizationId, setOrganizationId] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    data: uploadData,
    error: uploadError,
    loading: uploadLoading,
    fetchData: uploadFetchData,
  } = useFetch<any>();
  const { data, error, loading, fetchData } = useFetch<any>();
  const { setOrganizationInfo } = useAppContext();
  const { aggreement, address, companyInfo, contactDetails, urls } =
    useOnboardingContext();
  const email = getTokenFromLocalStore<string>("username") || "";
  const password = getTokenFromLocalStore<string>("password") || "";

  const { login } = useAuth();

  useEffect(() => {
    const token = (getTokenFromLocalStore("access_token") as string) || null;
    if (token) {
      const decodedToken = jwtDecode<{ companyId: string }>(token);
      setOrganizationId(decodedToken.companyId);
    }
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (uploadError) {
      toast.error(uploadError);
    }
  }, [uploadError]);

  const handleSubmit = async () => {
    if (companyInfo.companyprofileimage && companyInfo.companylegalname) {
      await uploadFetchData(() =>
        uploadFilesService(
          companyInfo.companyprofileimage,
          `${companyInfo.companylegalname.trim()}`
        )
      );
    } else {
      submitForm(null);
    }
  };

  const submitForm = useCallback(
    (imageUrl: any) => {
      const body = {
        legalName: companyInfo.companylegalname,
        displayName: companyInfo.companydisplayname,
        businessType: companyInfo.companyentitytype,
        sectorOrIndustry: companyInfo.companysector,
        registrationNo: companyInfo.companyregnumber,
        yearFounded: "",
        organizationId: organizationId,
        businessOverview: companyInfo.companyoverview,
        logoName: imageUrl,
        businessAddressDetails: {
          addressLine1: address.companyaddressone,
          addressLine2: address.companyaddresstwo,
          city: address.companycity,
          state: address.companystate,
          zipOrPostalCode: address.companyzipcode,
          country: address.companycountry,
        },
        businessContactDetails: {
          publicId: organizationId,
          phone: contactDetails.companyphonenumber,
          email: contactDetails.companyemail,
          website: contactDetails.companywebsite,
        },
        businessUrls: {
          termsOfUse: urls.companytermofuseurl,
          privacyPolicy: urls.companyprivacypolicyurl,
          serviceAgreement: urls.companyserviceagreementurl,
          faq: urls.companyfaqurl,
        },
        agreement: aggreement,
      };

      fetchData(() => onboardingService(body));
    },
    [
      address,
      aggreement,
      companyInfo,
      contactDetails,
      fetchData,
      organizationId,
      urls,
    ]
  );

  useEffect(() => {
    if (uploadData && companyInfo?.companyprofileimage) {
      submitForm(uploadData.data.url);
    }
  }, [uploadData, submitForm, companyInfo.companyprofileimage]);

  useEffect(() => {
    const fetchOrgInfo = async () => {
      if (data) {
        localStorage.removeItem("onboardingForm");
        localStorage.removeItem("address");
        localStorage.removeItem("contactDetails");
        localStorage.removeItem("companyInfo");
        localStorage.removeItem("urls");
        login(email, password, navigate);
      }
    };

    fetchOrgInfo();
  }, [data, email, navigate, password]);

  const handleNextPage = () => {
    switch (pathname) {
      case "/onboarding":
        return checkcompanyInfo();
      case "/onboarding/details":
        return checkcompanydetail();
      case "/onboarding/address":
        return checkAddress();
      case "/onboarding/urls":
        return checkUrls();
      case "onboarding/agreements":
        return checkAgreemnent();
    }
  };

  const checkcompanyInfo = () => {
    if (companyInfo.hasCompleted) {
      navigate("/onboarding/details");
    }
  };

  const checkcompanydetail = () => {
    if (contactDetails.hasCompleted) {
      navigate("/onboarding/address");
    }
  };

  const checkAddress = () => {
    if (address.hasCompleted) {
      return navigate("/onboarding/urls");
    }
  };

  const checkUrls = () => {
    return navigate("/onboarding/agreements");
  };
  const checkAgreemnent = () => {
    if (aggreement === true) {
      return navigate("/");
    }
  };

  const updateNavigationHistory = (
    pathname: string,
    historyStack: React.MutableRefObject<string[]>,
    setActivePaths: React.Dispatch<React.SetStateAction<string[]>>
  ): void => {
    const currentIndex = historyStack.current.indexOf(pathname);
    if (currentIndex === -1) {
      setActivePaths((prevActivePaths) => [...prevActivePaths, pathname]);
      historyStack.current.push(pathname);
    } else {
      setActivePaths((prevActivePaths) =>
        prevActivePaths.slice(0, currentIndex + 1)
      );
      historyStack.current = historyStack.current.slice(0, currentIndex + 1);
    }
  };

  return {
    handleBack,
    handleNextPage,
    updateNavigationHistory,
    handleSubmit,
    loading,
    error,
    uploadLoading,
  };
}
