import React from "react";

interface PDFViewerProps {
  url: string;
  width?: string;
  height?: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ url }) => {
  const viewerUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
    url
  )}`;

  return (
    <iframe
      src={viewerUrl}
      width="100%"
      height="100%"
      style={{ border: "none" }}
      title="PDF Viewer"
      allowFullScreen
    ></iframe>
  );
};

export default PDFViewer;
