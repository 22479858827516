import apiService from "./api";

const getallframeworks = () => {
  return apiService(
    `/vegeel/frameworks/all-framework-without-details`,
    "get",
    null,
    true
  );
};

const getFramweworkReqFromTenant = (publicId: any) => {
  return apiService(
    `/frameworks?vegeelFrameworkPublicIds=${publicId}`,
    "get",
    null,
    true
  );
};

const getNonApplicableReq = (publicId: string) => {
  return apiService(
    `/frameworks?applicable=false&vegeelFrameworkPublicIds=${publicId}`,
    "get",
    null,
    true
  );
};

const getFrameworkByName = (name: string) => {
  return apiService(`/vegeel/frameworks?names=${name}`, "get", null, true);
};

const getFrameworksByIds = (publicIds: string[]) => {
  const queryString = publicIds
    .map((id) => `publicIds=${encodeURIComponent(id)}`)
    .join("&");

  return apiService(`/vegeel/frameworks?${queryString}`, "get", null, true);
};

const getFrameworkStats = (name: string) => {
  return apiService(
    `/frameworks/framework-stats/?name=${name}`,
    "get",
    null,
    true
  );
};

const postFrameworkReq = (body: any) => {
  return apiService("/frameworks", "post", body, true);
};

const updateFrameworkReq = (publicId: string, body: any) => {
  return apiService(`/frameworks/${publicId}`, "put", body, true);
};

const setToNonApllicable = (body: any) => {
  return apiService(
    `/frameworks/change-applicability-status`,
    "post",
    body,
    true
  );
};

const getReqHistory = (publicId: string) => {
  return apiService(
    `history?entityPublicId=${publicId}&page=0&size=20`,
    "get",
    null,
    true
  );
};

const getTotalReqCount = (publicId: string) => {
  return apiService(
    `/vegeel/frameworks/requirements/count/${publicId}`,
    "get",
    null,
    true
  );
};

const createComment = (reqId: string, body: any, path: string) => {
  return apiService(`/${path}/${reqId}/create-comment`, "post", body, true);
};

const getComments = (reqId: string, path: string) => {
  return apiService(`/${path}/${reqId}/comments`, "get", null, true);
};

const replyComment = (
  reqId: string,
  commentId: string,
  body: any,
  path: string
) => {
  return apiService(
    `/${path}/${reqId}/${commentId}/reply-to-comment`,
    "post",
    body,
    true
  );
};
const getReplyComment = (reqId: string, commentId: string, path: string) => {
  return apiService(
    `/${path}/${reqId}/${commentId}/replies`,
    "get",
    null,
    true
  );
};

export {
  getallframeworks,
  getFrameworksByIds,
  postFrameworkReq,
  getFramweworkReqFromTenant,
  updateFrameworkReq,
  getFrameworkStats,
  setToNonApllicable,
  getTotalReqCount,
  getNonApplicableReq,
  createComment,
  getFrameworkByName,
  getComments,
  replyComment,
  getReplyComment,
  getReqHistory,
};
