import { Timeline } from "antd";
import { ReactComponent as Clock } from "assets/icons/clock.svg";
import CustomIcon from "components/custom-icon";
import { formatDate } from "utils/formatDate";
import { IHistoryItem } from "..";
import dummy from "assets/images/dummy.png";

interface ITimelineComponent {
  history: IHistoryItem;
}

const TimelineComponent = ({ history }: ITimelineComponent) => {
  const timelineItems = [
    {
      dot: <CustomIcon SvgIcon={Clock} size={24} />,
      children: (
        <div>
          <div className="font-[500] text-[14px] leading-6">
            {history.description}
          </div>
          <div className="flex gap-2 items-center">
            <span className="font-[500] text-[14px] leading-6 text-[#455468]">
              Modified by:
            </span>
            <img
              src={history.personnel.profilePicture ?? dummy}
              alt=""
              className="h-[30px] w-[30px] object-contain rounded-full"
            />
            <span className="font-[400] text-[14px] leading-6">
              {history.personnel.firstName} {history.personnel.lastName}
            </span>
          </div>
          <div>
            <span className="font-[500] text-[14px] leading-6 text-[#455468]">
              Last modified:
            </span>{" "}
            <span className="font-[400] text-[14px] leading-6">
              {formatDate(history.lastModifiedDate)}
            </span>
          </div>
        </div>
      ),
    },
  ];

  return <Timeline className="h-full" items={timelineItems} />;
};

export default TimelineComponent;
