import { useLocation } from "react-router-dom";

export function OnboardingTabs() {
  const { pathname } = useLocation();

  return (
    <section className="flex gap-2">
      <div
        className={`${
          pathname === "/onboarding"
            ? " text-[#111111] border-b-2 border-b-[#111111] text-sm"
            : "text-[#8897AE] cursor-pointer text-sm"
        } p-1`}
      >
        Company information
      </div>
      <div
        className={`${
          pathname === "/onboarding/details"
            ? " text-[#111111] border-b-2 border-b-[#111111] text-sm"
            : "text-[#8897AE] cursor-pointer text-sm"
        } p-1`}
      >
        Contact details
      </div>
      <div
        className={`${
          pathname === "/onboarding/address"
            ? " text-[#111111] border-b-2 border-b-[#111111] text-sm"
            : "text-[#8897AE] cursor-pointer text-sm"
        } p-1 `}
      >
        Address
      </div>
      <div
        className={`${
          pathname === "/onboarding/urls"
            ? " text-[#111111] border-b-2 border-b-[#111111] text-sm"
            : "text-[#8897AE] cursor-pointer text-sm"
        } p-1 `}
      >
        URLs
      </div>
      <div
        className={`${
          pathname === "/onboarding/agreements"
            ? " text-[#111111] border-b-2 border-b-[#111111] text-sm"
            : "text-[#8897AE] cursor-pointer text-sm"
        } p-1 `}
      >
        Agreements
      </div>
    </section>
  );
}
