import { Avatar, Input } from "antd";
import emptycomment from "assets/icons/Empty State.svg";
import { useAppContext } from "context";
import useFetch from "hooks/useFetch";
import { useCallback, useEffect, useState } from "react";
import {
  createComment,
  getComments,
  getReplyComment,
  replyComment,
} from "services";
import { toast } from "sonner";
import { INonApplicableItem, IReqItem } from "types/framework";
import { getTimeAgo } from "utils/formatDate";
import { cleanUserRole, getInitials, getPersonnelProfile } from "utils/general";
import { TextEditor } from "../text-editor";
import { IEvidenceHubItem } from "types/evidence-hub";

interface Props {
  reqItems?: IReqItem | INonApplicableItem | IEvidenceHubItem;
  path: string;
}

interface IData {
  publicId: string;
  message: string;
  replies: null;
  parentComment: null;
  createdByRole: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  owner: string;
}

interface ApiResponse {
  timestamp: string;
  data: IData;
  isSuccess: true;
  isError: false;
  description: string;
  status: string;
}

interface GetCommentResponseData {
  publicId: string;
  message: string;
  replies: any[];
  parentComment: null;
  createdByRole: null;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  owner: string;
}

interface GetCommentResponse {
  timestamp: string;
  data: GetCommentResponseData[];
  isSuccess: true;
  isError: false;
  description: string;
  status: string;
}

export default function CommentTab({ reqItems, path }: Props) {
  const [allComments, setAllComments] = useState<GetCommentResponseData[]>([]);
  // const [personnelData, setPersonnelData] = useState<IUserDataDetails[]>([]);
  const [replyToCommentId, setReplyToCommentId] = useState<string | null>(null);
  const [replyContent, setReplyContent] = useState<string>("");
  const [repliesMap, setRepliesMap] = useState<{ [key: string]: any[] }>({});
  const [visibleRepliesMap, setVisibleRepliesMap] = useState<{
    [key: string]: boolean;
  }>({});

  // const {} = useAppContext()

  const { getUserInfo, personnelInfoState } = useAppContext();

  const userInfo = getUserInfo();

  const {
    data: getCommentData,
    error: getCommentError,
    loading: getCommentLoading,
    fetchData: getCommentFetch,
  } = useFetch<GetCommentResponse>();

  const {
    data: postCommetReplyData,
    error: postCommetReplyError,
    loading: postCommetReplyLoading,
    fetchData: postCommetReplyFetch,
  } = useFetch();

  const {
    data: getCommetReplyData,
    error: getCommetReplyError,
    loading: getCommetReplyLoading,
    fetchData: getCommetReplyFetch,
  } = useFetch<any>();

  const {
    data: createCommentData,
    error: createCommentError,
    loading: createCommentLoading,
    fetchData: createCommentFetch,
  } = useFetch<ApiResponse>();

  // useEffect(() => {
  //   getPersonnelFetchData(() => getAllPersonnelService());
  // }, [getPersonnelFetchData]);

  useEffect(() => {
    if (reqItems) {
      getCommentFetch(() => getComments(reqItems?.publicId, path));
    }
  }, [reqItems, getCommentFetch]);

  useEffect(() => {
    if (createCommentData) {
      const newComment: GetCommentResponseData = {
        publicId: createCommentData.data.publicId,
        message: createCommentData.data.message,
        replies: [],
        parentComment: null,
        createdByRole: null,
        createdBy: createCommentData.data.createdBy,
        createdDate: createCommentData.data.createdDate,
        lastModifiedBy: createCommentData.data.lastModifiedBy,
        lastModifiedDate: createCommentData.data.lastModifiedDate,
        owner: createCommentData.data.owner,
      };

      setAllComments((prev) => [...prev, newComment]);
    }
  }, [createCommentData]);

  useEffect(() => {
    if (getCommentData) {
      setAllComments(getCommentData.data);
    }
  }, [getCommentData]);

  // useEffect(() => {
  //   if (getPersonnelData) {
  //     setPersonnelData(getPersonnelData.data);
  //   }
  // }, [getPersonnelData]);

  useEffect(() => {
    if (createCommentError) {
      toast.error(createCommentError);
    }
  }, [createCommentError]);

  useEffect(() => {
    if (postCommetReplyData) {
      setReplyToCommentId(null);
      setReplyContent("");
    }
  }, [postCommetReplyData]);

  useEffect(() => {
    if (getCommetReplyData && replyToCommentId) {
      setRepliesMap((prev) => ({
        ...prev,
        [replyToCommentId]: getCommetReplyData.data,
      }));
    }
  }, [getCommetReplyData, replyToCommentId]);

  const handleSend = async (content: string) => {
    if (!reqItems) return;

    const body = {
      message: content,
    };

    createCommentFetch(() => createComment(reqItems.publicId, body, path));
  };

  const handleFetchReplies = useCallback(
    async (commentId: string) => {
      if (!reqItems) return;

      setVisibleRepliesMap((prev) => ({
        ...prev,
        [commentId]: !prev[commentId],
      }));

      if (!repliesMap[commentId]) {
        setReplyToCommentId(commentId);
        getCommetReplyFetch(() =>
          getReplyComment(reqItems.publicId, commentId, path)
        );
      }
    },
    [reqItems, repliesMap, getCommetReplyFetch]
  );

  const handleReply = (commentId: string) => {
    setReplyToCommentId(commentId);
  };

  const handleReplySend = () => {
    if (!reqItems) return;
    if (replyToCommentId && replyContent) {
      const body = {
        message: replyContent,
        // ownerId: userInfo?.id,
      };

      postCommetReplyFetch(() =>
        replyComment(reqItems.publicId, replyToCommentId, body, path)
      );
    }
  };

  const getOwnerInfo = useCallback(
    (ownerId: string) => {
      return personnelInfoState.content.find((p) => p.publicId === ownerId);
    },
    [personnelInfoState]
  );

  return (
    <div className="p-[10px] px-[24px]">
      <TextEditor onSend={handleSend} />
      {allComments.length === 0 ? (
        <div className="flex flex-col items-center justify-center py-4">
          <div>
            <img src={emptycomment} alt="No comments" />
          </div>
          <div className="text-[16px] font-[500] text-[#111111] leading-[28px]">
            No comments yet.
          </div>
          <div className="text-[12px] font-[400] text-[#455468] leading-[20px]">
            Type in message to leave a comment
          </div>
        </div>
      ) : (
        <div className="pt-5 flex flex-col gap-4">
          {allComments.map((comment) => {
            const {
              publicId,
              message,
              owner,
              createdByRole,
              lastModifiedDate,
            } = comment;

            const ownerInfo = getOwnerInfo(owner);

            return (
              <div key={publicId} className="">
                <div className="flex justify-start items-center w-[50%] gap-[4px]">
                  {getPersonnelProfile(
                    personnelInfoState.content,
                    ownerInfo?.firstName
                  ) === null ? (
                    <Avatar className="w-[28px] h-[28px] rounded-full">
                      {getInitials(
                        `${ownerInfo?.firstName} ${ownerInfo?.lastName}`
                      )}
                    </Avatar>
                  ) : (
                    <img
                      src={getPersonnelProfile(
                        personnelInfoState.content,
                        ownerInfo?.firstName
                      )}
                      className="rounded-full w-[28px] h-[28px]"
                      alt={ownerInfo?.firstName}
                    />
                  )}

                  <h1 className="text-[#111111] text-[0.875rem] font-medium">
                    {ownerInfo?.firstName} {ownerInfo?.lastName.substring(0, 1)}
                  </h1>
                  <h1 className="py-[2px] px-[8px] rounded-[6px] bg-[#F2F5FF] text-[#4A72FF] text-[0.75rem] font-medium mx-1">
                    {createdByRole ? cleanUserRole(createdByRole) : ""}
                  </h1>
                  <h1 className="text-[#8897AE] text-[0.75rem] font-medium">
                    <span className="text-[#E9EFF6]">|</span>{" "}
                    {getTimeAgo(lastModifiedDate)}
                  </h1>
                </div>
                <p
                  className="py-[8px] text-[0.8125rem] text-[#455468] leading-[20px] tracking-wide"
                  dangerouslySetInnerHTML={{ __html: message }}
                />

                <div className="flex gap-4">
                  <button
                    className="text-main_color font-medium text-sm "
                    onClick={() => handleReply(publicId)}
                  >
                    Reply
                  </button>
                  <button
                    className="text-[#1B4DFF] text-[12px] font-medium"
                    onClick={() => handleFetchReplies(publicId)}
                  >
                    View Replies
                  </button>

                  {/* {replies && replies.length > 0 && (
                    <div>
                      <h1 className="text-[#1B4DFF] text-[12px] font-medium">
                        {replies.length} Replies
                      </h1>
                    </div>
                  )} */}
                </div>
                {replyToCommentId === publicId && (
                  <div className="mt-4">
                    <Input
                      placeholder="Write your reply..."
                      className="py-2 px-1 border rounded-md"
                      value={replyContent}
                      onChange={(e) => setReplyContent(e.target.value)}
                    />
                    <button
                      // type="primary"
                      className="mt-2 "
                      onClick={handleReplySend}
                    >
                      {postCommetReplyLoading ? "Sending reply" : "Send Reply"}
                    </button>
                  </div>
                )}
                {visibleRepliesMap[publicId] && repliesMap[publicId] && (
                  <div className="border border-[#E9EFF6] bg-[#E9EFF6] rounded-[6px] p-[12px] mt-4">
                    {repliesMap[publicId].map((reply, index) => (
                      <div key={index} className="flex flex-col">
                        <div className="flex justify-start items-center gap-[4px]">
                          {getPersonnelProfile(
                            personnelInfoState.content,
                            reply.createdBy
                          ) === null ? (
                            <Avatar className="w-[28px] h-[28px] rounded-full">
                              {getInitials(reply.createdBy)}
                            </Avatar>
                          ) : (
                            <img
                              src={getPersonnelProfile(
                                personnelInfoState.content,
                                reply.createdBy
                              )}
                              className="rounded-full w-[28px] h-[28px]"
                              alt={reply.createdBy}
                            />
                          )}

                          <h1 className="text-[#111111] text-[0.875rem] font-medium">
                            {reply.createdBy}
                          </h1>
                          <h1 className="py-[2px] px-[8px] rounded-[6px] bg-[#F2F5FF] text-[#4A72FF] text-[0.75rem] font-medium mx-1">
                            {cleanUserRole(reply.createdByRole)}
                          </h1>
                          <h1 className="text-[#8897AE] text-[0.75rem] font-medium">
                            <span className="text-[#E9EFF6]">|</span>{" "}
                            {getTimeAgo(reply.lastModifiedDate)}
                          </h1>
                        </div>
                        <p
                          className="py-[8px] text-[0.8125rem] text-[#455468] leading-[20px] tracking-wide"
                          dangerouslySetInnerHTML={{ __html: reply.message }}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {/* {replies && (
                  <div className="border border-[#E9EFF6] bg-[#E9EFF6] rounded-[6px] p-[12px]">
                    {replies.map((reply, index) => (
                      <div key={index}>
                        <div className="flex justify-start items-center gap-[4px]">
                          <img
                            src={""}
                            alt="human"
                            className="w-[28px] h-[28px] rounded-full"
                          />
                          <h1 className="text-[#111111] text-[0.875rem] font-medium">
                            {reply.owner}
                          </h1>
                          <h1 className="py-[2px] px-[8px] rounded-[6px] bg-[#F2F5FF] text-[#4A72FF] text-[0.75rem] font-medium mx-1">
                            Team member
                          </h1>
                          <h1 className="text-[#8897AE] text-[0.75rem] font-medium">
                            <span className="text-[#E9EFF6]">|</span> 3mins ago
                          </h1>
                        </div>
                        <p className="py-[8px] text-[0.8125rem] text-[#455468] leading-[20px] tracking-wide">
                          {reply.message}
                        </p>
                      </div>
                    ))}
                  </div>
                )} */}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
