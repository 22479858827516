import { Checkbox, Select } from "antd";
import { ReactComponent as img1 } from "assets/icons/Iso1.svg";
import { ReactComponent as img3 } from "assets/icons/iso2.svg";
import { ReactComponent as img4 } from "assets/icons/iso3.svg";
import { ReactComponent as img6 } from "assets/icons/ndpb.svg";
import { ReactComponent as img5 } from "assets/icons/PCI.svg";
import { ReactComponent as img2 } from "assets/icons/soc1.svg";
import CustomIcon from "components/custom-icon";
import Loader from "components/loader";
import { useAppContext, useFrameworkContext, useSidebarContext } from "context";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import {
  getAllEvidenceService,
  updateDocumentStatusService,
  updateEvidenceService,
} from "services/evidenceHub";
import { toast } from "sonner";
import { IEvidenceHubItem } from "types/evidence-hub";
import { IPersonnel } from "types/personnel";
import { replaceUnderscoresWithSpaces } from "utils/general";

interface IEvidenceDocTab {
  evidence: IEvidenceHubItem;
  getAllEvidenceFetchData: () => void;
  personnel: any[];
  tempDocumentName: string;
  uploadedFile: any;
  handleUpdateEvidence: any;
  uploadResponse: any;
  uploadLoading: any;
  // isEditable: boolean;
}

interface IOptionType {
  label: string;
  value: string;
  person: IPersonnel;
}

export default function DocumentTypeTab({
  evidence,
  personnel,
  getAllEvidenceFetchData,
  tempDocumentName,
  uploadedFile,
  handleUpdateEvidence,
  uploadResponse,
  uploadLoading,
}: IEvidenceDocTab) {
  const { personnelInfoState } = useAppContext();
  const { activeFrameworks } = useFrameworkContext();

  const { userRole } = useSidebarContext();
  const [classification, setClassificationState] = useState(
    evidence.classification || ""
  );
  const [reviewCycle, setReviewCycleState] = useState(
    evidence.reviewCycle || ""
  );
  const [version, setVersionState] = useState(evidence.version || "");
  const [ownerState, setOwnerState] = useState<IPersonnel>(evidence.owner);
  const [ownerPublicId, setOwnerPublicId] = useState<string | null>(
    evidence.owner.publicId
  );
  const [aproverPublicId, setApproverPublicId] = useState<string | null>(
    evidence.approver.publicId
  );
  const [ownersArr, setOwnersArr] = useState<IOptionType[]>([]);
  const [approverArr, setApproverArr] = useState<IOptionType[]>([]);
  const [approverState, setApproverState] = useState(evidence.approver || "");
  const [localFrameworkIds, setLocalFrameworkIds] = useState(
    evidence.vegeelFrameworkPublicIds || []
  );

  useEffect(() => {
    if (evidence) {
      const owners = personnelInfoState.content
        .filter(
          (person) =>
            person.roleName === "REVIEWER" || person.roleName === "SUPER_ADMIN"
        )
        .map((personel) => {
          return {
            label: `${personel.firstName} ${personel.lastName}`,
            value: personel.publicId,
            person: personel,
          };
        });

      const approvers = personnelInfoState.content
        .filter(
          (person) =>
            person.roleName === "APPROVER" || person.roleName === "SUPER_ADMIN"
        )
        .map((personel) => {
          return {
            label: `${personel.firstName} ${personel.lastName}`,
            value: personel.publicId,
            person: personel,
          };
        });

      setApproverArr(approvers);
      setOwnersArr(owners);
    }
  }, [evidence, personnelInfoState]);

  useEffect(() => {
    setLocalFrameworkIds(evidence.vegeelFrameworkPublicIds || []);
  }, [evidence, setLocalFrameworkIds]);

  const onChange = (e: any) => {
    const { value, checked } = e.target;
    setLocalFrameworkIds((prev: any) =>
      checked ? [...prev, value] : prev.filter((id: any) => id !== value)
    );
  };

  const handleUpdateOwner = (item: string) => {
    setOwnerPublicId(item);
  };

  const handleUpdateApprover = (item: string) => {
    setApproverPublicId(item);
  };

  const {
    data: dataUpdateDocumentStatus,
    error: errorUpdateDocumentStatus,
    loading: loadingUpdateDocumentStatus,
    fetchData: fetchUpdateDocumentStatus,
  } = useFetch();

  const handleRejectStatus = () => {
    fetchUpdateDocumentStatus(() =>
      updateDocumentStatusService(evidence.publicId, "REJECT")
    );
  };
  const handleApproveStatus = () => {
    fetchUpdateDocumentStatus(() =>
      updateDocumentStatusService(evidence.publicId, "APPROVED")
    );
  };

  useEffect(() => {
    const data = {
      documentName: tempDocumentName || evidence.documentName,
      classification,
      reviewCycle,
      ownerPublicId: ownerPublicId,
      version,
      approverPublicId: aproverPublicId,
      status: evidence.status,
      expiresIn: evidence.expiresIn,
      expirationDate: evidence.expirationDate,
      vegeelFrameworkPublicIds: localFrameworkIds,
      documentUrl: uploadedFile,
    };

    handleUpdateEvidence(data);
  }, [
    aproverPublicId,
    classification,
    evidence,
    handleUpdateEvidence,
    localFrameworkIds,
    ownerPublicId,
    reviewCycle,
    tempDocumentName,
    uploadResponse,
    uploadedFile,
    version,
  ]);

  const handleUpdateStatus = async () => {
    await fetchUpdateDocumentStatus(() =>
      updateDocumentStatusService(evidence.publicId, "REQUEST_APPROVAL")
    );
  };

  useEffect(() => {
    if (dataUpdateDocumentStatus) {
      toast.success("Document Updated Sucessfully");
      getAllEvidenceFetchData();
    }
  }, [dataUpdateDocumentStatus]);

  const options = [
    { value: "PUBLIC", label: "Public" },
    { value: "INTERNAL_USE", label: "Internal use" },
    { value: "CONFIDENTIAL", label: "Confidential" },
    { value: "PRIVATE", label: "Private" },
  ];

  const review = [
    { value: "YEARLY", label: "Yearly" },
    { value: "QUARTERLY", label: "Quarterly" },
    { value: "MONTHLY", label: "Monthly" },
  ];

  if (!evidence) {
    return <Loader />;
  }

  if (uploadLoading && loadingUpdateDocumentStatus) {
    <Loader />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div>
        <label htmlFor="" className="font-medium text-sm">
          Classification<sup className="text-[#1B4DFF]">*</sup>
        </label>
        <div className="mt-1">
          <div className="w-full bg-white border border-[#AFBACA] rounded-md text-sm p-1.5  placeholder:text-[#AFBACA] outline-none mt-1">
            <Select
              variant="borderless"
              placeholder={"eg. Public, Internal use, Confidential, Private"}
              options={options}
              onChange={(value) => {
                setClassificationState(value);
              }}
              className="w-full"
              value={replaceUnderscoresWithSpaces(classification)}
              defaultValue={replaceUnderscoresWithSpaces(classification)}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <div className="w-full">
          <label htmlFor="" className="font-medium text-sm">
            Version<sup className="text-[#1B4DFF]">*</sup>
          </label>
          <input
            className="w-full bg-white border border-[#AFBACA] rounded-md text-sm p-2.5 placeholder:text-[#AFBACA] outline-none"
            type="text"
            placeholder="Document name"
            onChange={(e) => setVersionState(e.target.value)}
            defaultValue={version}
          />
        </div>

        <div className="w-full">
          <label htmlFor="" className="font-medium text-sm">
            Review cycle<sup className="text-[#1B4DFF]">*</sup>
          </label>
          <div className="w-full bg-white border border-[#AFBACA] rounded-md text-sm p-1.5  placeholder:text-[#AFBACA] outline-none mt-1">
            <Select
              variant="borderless"
              placeholder="eg. Yearly, Quarterly"
              options={review}
              defaultValue={reviewCycle}
              onChange={(value) => setReviewCycleState(value)}
              className="w-full"
            />
          </div>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <div className="w-full">
          <label htmlFor="" className="font-medium text-sm">
            Owner<sup className="text-[#1B4DFF]">*</sup>
          </label>
          <div className="w-full bg-white border border-[#AFBACA] rounded-md text-sm p-1.5  placeholder:text-[#AFBACA] outline-none mt-1">
            <Select
              variant="borderless"
              placeholder="Select team member"
              options={ownersArr}
              defaultValue={ownerState.firstName + " " + ownerState.lastName}
              onChange={(evidence) => handleUpdateOwner(evidence)}
              className="w-full"
            />
          </div>
        </div>

        <div className="w-full">
          <label htmlFor="" className="font-medium text-sm">
            Approval<sup className="text-[#1B4DFF]">*</sup>
          </label>
          <div className="w-full bg-white border border-[#AFBACA] rounded-md text-sm p-1.5 placeholder:text-[#AFBACA] outline-none mt-1">
            <Select
              variant="borderless"
              placeholder="Select team member"
              options={approverArr}
              defaultValue={
                approverState.firstName + " " + approverState.lastName
              }
              onChange={(value) => setApproverPublicId(value)}
              className="w-full"
            />
          </div>
        </div>
      </div>

      <div>
        <label className="font-medium text-sm">
          Tag to frameworks<sup className="text-[#1B4DFF]">*</sup>
        </label>
        <div className="grid grid-cols-2 gap-y-4 py-4 border-b border-[#D7DFE9]">
          {activeFrameworks?.map((framework) => {
            return (
              <div className="flex items-center gap-4" key={framework.publicId}>
                <Checkbox
                  value={framework.publicId}
                  onChange={onChange}
                  checked={localFrameworkIds.includes(framework.publicId)}
                />
                <div className="flex items-center gap-2">
                  <img
                    src={framework.logo}
                    alt={framework.name}
                    className="h-[24px] w-[24px] object-cover"
                  />
                  <p className="text-[#455468] font-medium text-sm">
                    {framework.name}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <>
        {userRole === "REVIEWER" && (
          <button
            onClick={handleUpdateStatus}
            disabled={evidence?.status === "REQUEST_APPROVAL"}
            className={`w-full py-2 rounded-md text-white font-medium text-sm  ${
              evidence?.status === "REQUEST_APPROVAL"
                ? "bg-[#92aaff] border border-[#92aaff] cursor-not-allowed"
                : "bg-[#1B4DFF] border border-[#1B4DFF]"
            }`}
          >
            {evidence?.status === "REQUEST_APPROVAL"
              ? "Pending Approval"
              : "Request Approval"}
          </button>
        )}
      </>

      {(evidence?.status === "IN_REVIEW" ||
        evidence?.status === "REQUEST_APPROVAL") && (
        <>
          {(userRole === "SUPER_ADMIN" || userRole === "APPROVER") && (
            <div className="flex gap-4">
              <button
                className="w-full py-2 rounded-md text-white font-medium text-sm bg-[#1B4DFF] border border-[#1B4DFF]"
                onClick={handleApproveStatus}
              >
                Approve
              </button>
              <button
                className="w-full py-2 rounded-md text-[#E92215] font-medium text-sm bg-[#fff] border border-[#FFC5C1]"
                onClick={handleRejectStatus}
              >
                Reject
              </button>
            </div>
          )}
        </>
      )}

      {evidence?.status === "REJECT" && (
        <>
          {(userRole === "SUPER_ADMIN" || userRole === "APPROVER") && (
            <div className="flex gap-4">
              <button
                className="w-full py-2 rounded-md text-white font-medium text-sm bg-[#1B4DFF] border border-[#1B4DFF]"
                onClick={handleApproveStatus}
              >
                Approve
              </button>
            </div>
          )}
        </>
      )}
      {evidence?.status === "APPROVED" && (
        <>
          {(userRole === "SUPER_ADMIN" || userRole === "APPROVER") && (
            <div className="flex gap-4">
              <button
                className="w-full py-2 rounded-md text-[#E92215] font-medium text-sm bg-[#fff] border border-[#FFC5C1]"
                onClick={handleRejectStatus}
              >
                Reject
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
