import {
  IOrgDataResponse,
  IUserDataDetails,
  useAppContext,
} from "context/home/app";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getOrgInfoService, loginService } from "services";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { IPersonnel } from "types/personnel";
import { clearKey, getTokenFromLocalStore, saveKeyToLocalStore } from "utils";
import { handleError } from "utils/error";
import { storeUserRole } from "utils/general";

// Define types
interface AuthContextProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (
    username: string,
    password: string,
    navigate: NavigateFunction,
    isImplementer?: boolean,
    companyId?: string
  ) => Promise<void>;
  email: string;
}

interface LoginResponse {
  userData: IPersonnel;
  accessToken: string;
  refreshToken: string;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const storedAuth = getTokenFromLocalStore("isAuthenticated") as string;
    return storedAuth ? JSON.parse(storedAuth) : false;
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<LoginResponse | null>(null);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [tokenSaved, setTokenSaved] = useState<boolean>(false);
  const [orgInfoFetched, setOrgInfoFetched] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const { setOrganizationInfo, setUserInfo } = useAppContext();

  useEffect(() => {
    if (orgInfoFetched) {
      toast.success("Login successful");
      saveKeyToLocalStore("isAuthenticated", JSON.stringify(true));
      setIsAuthenticated(true);
      clearKey("username");
      clearKey("password");
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } else if (loginError) {
      toast.error(loginError);
    }
  }, [orgInfoFetched, loginError]);

  useEffect(() => {
    if (loginData) {
      saveKeyToLocalStore("access_token", loginData.accessToken);
      saveKeyToLocalStore("refresh_token", loginData.refreshToken);
      setUserInfo(loginData.userData);
      storeUserRole(loginData.userData);
      setTokenSaved(true);
    }
  }, [loginData, setUserInfo]);

  useEffect(() => {
    const fetchOrgInfo = async () => {
      if (tokenSaved && loginData) {
        try {
          const response: IOrgDataResponse = await getOrgInfoService();
          if (response) {
            setOrganizationInfo(response.data);
            setOrgInfoFetched(true);
          }
        } catch (error: any) {
          const errorStatus = error.response?.data?.title;

          if (errorStatus === "Not Found") {
            setUserInfo(loginData.userData);

            saveKeyToLocalStore("isAuthenticated", JSON.stringify(true));

            setTimeout(() => {
              window.location.href = "/onboarding";
            }, 1000);
            return;
          }
        }
      }
    };

    fetchOrgInfo();
  }, [loginData, tokenSaved]);

  const login = async (
    username: string,
    password: string,
    navigate: any,
    isImplementer?: boolean,
    companyId?: string
  ) => {
    setIsLoading(true);
    const formData = isImplementer
      ? { password, email: username, organizationId: companyId }
      : {
          password,
          email: username,
        };

    setEmail(formData.email);
    localStorage.setItem("email", formData.email);
    saveKeyToLocalStore("username", formData.email);
    saveKeyToLocalStore("password", formData.password);

    try {
      const response = await loginService(formData);
      setLoginData(response.data);
    } catch (errorRes: any) {
      const errorMessage = handleError(errorRes);
      setLoginError(errorMessage);
      setIsLoading(false);

      if (errorRes.response) {
        const { data } = errorRes.response;
        if (data && data.detail && data.detail.includes("Please provide OTP")) {
          navigate("/auth/otp");
        } else {
          console.error(data?.description || "An error occurred");
          toast.error(errorMessage);
        }
      } else {
        console.error("Error:", errorRes.message);
        setEmail(formData.email);
      }
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, login, email }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
